import { useContext } from 'react';
import { AppContext } from '../lib/ApBlaen';
import Alert from 'react-bootstrap/Alert';

function Neges(props) {
	const { app } = useContext(AppContext);
	if (app.neges) {
		return <Alert variant={app.neges.math || 'info'}>{app.neges.testun}</Alert>;
	}
}

export default Neges;