import Tabs from 'react-bootstrap/Tabs';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Tabiau({defaultActiveKey, children}) {
	const location = useLocation();
	const [tab, setTab] = useState(location.hash.substring(1) || defaultActiveKey);

	useEffect(() => {
		setTab(location.hash.substring(1) || defaultActiveKey);
	}, [location]);

	return (
		<Tabs className="mb-3" activeKey={tab} onSelect={
			(tab) => {
				window.location.hash = '#' + tab;
				setTab(tab);
			}
		}>{children}</Tabs>
	);
}