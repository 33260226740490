import { Trans, t } from '@lingui/macro'
import MaesTestun from '../darnau/MaesTestun';
import Bloc from './Bloc';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { glanhauTestun } from '../cyffredinol';
import { forwardRef, useRef, useImperativeHandle, useCallback } from 'react';
import RhestrFfeiliau from '../darnau/RhestrFfeiliau';
import EnwCofnod from '../darnau/EnwCofnod';
import MaesFfeiliau from '../darnau/MaesFfeiliau';
import Datgelu from '../darnau/Datgelu';
import { useBlwyddyn } from '../lib/ApBlaen';

const Technegol = forwardRef(function Technegol({cais, slot, lle, llwybr, blociau, setBlociau, nesaf}, ref) {
	const blwyddyn = useBlwyddyn();
	const bloc = 'technegol';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const formRef = useRef(null);

	function arAnfon(e) {
		e.preventDefault();
		if (!e.target.checkValidity()) {
			e.target.reportValidity();
		} else if (cadwFfurflen(e.target)) {
			nesaf();
		}
	}

	const cadwFfurflen = useCallback((form) => {
		const formData = new FormData(form);
		const data = {};
		for (const key of ['technegol', 'hawlfraint']) {
			if (formData.has(key)) {
				const val = glanhauTestun(formData.get(key));
				if (val !== cais[val]) {
					data[key] = val;
				}
			}
		}
		const cwblhawyd = form.checkValidity();
		if (cwblhawyd !== !!blociau[bloc]) {
			data[`gofynion.${bloc}`] = cwblhawyd;
			blociau[bloc] = cwblhawyd;
			setBlociau({...blociau});
		}
		if (Object.keys(data).length) {
			updateDoc(cais.ref, data);
		}
		return true;
	}, [cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	return (
	<Bloc label={t`Technegol`} formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>

		<p><Trans>Er mwyn sicrhau bod trefniadau eich perfformiad yn hwylus a fyddai modd i chi nodi unrhyw fanylion technegol perthnasol.</Trans></p>

		{ lle?._gwybodaeth &&
			<Datgelu label={t`Dangos manylion technegol`}>
				<div className="content" dangerouslySetInnerHTML={{__html: lle._gwybodaeth}}/>
			</Datgelu>
			// <div className="mb-3">
			// 	<p><b><EnwCofnod eitem={lle}/></b></p>
			// 	<div className="blwch content" dangerouslySetInnerHTML={{__html: lle._gwybodaeth}}/>
			// </div>
		}

		{ lle?.id &&
			<RhestrFfeiliau className="mb-3" path={`${blwyddyn}/llefydd/${lle.id}`}>
				<div className="form-label"><Trans>Manylion technegol y llwyfan hwn</Trans></div>
			</RhestrFfeiliau>
		}

		{ lle?._technegol &&
			<>
				<p><Trans>Bydd yr offer isod ar gael i chi ond a fyddai modd i chi nodi os ydych chi am eu defnyddio er mwyn sicrhau eu bod ar gael ar gyfer eich perfformiad.</Trans></p>
				<Datgelu label={t`Dangos offer`}>
					<div className="content" dangerouslySetInnerHTML={{__html: lle._technegol}}/>
				</Datgelu>
			</>
		}

		<MaesTestun name="technegol" defaultValue={ cais.technegol } type="textarea" label={ t`Eich manylion technegol` } rows="10">
		</MaesTestun>

		<MaesFfeiliau label={t`Ffeiliau technegol`} className="mb-3" path={llwybr + '/technegol'} accept="dogfen"
			arNewid={ffeiliau => updateDoc(cais.ref, {ffeiliau: ffeiliau?.length || 0})} golygu={true}>
			<Trans>Llwythwch ffeiliau sy’n cynnwys eich manylion technegol a chynllun llwyfan, os yn berthnasol.</Trans>
		</MaesFfeiliau>

		<MaesFfeiliau label={t`Sain a fideo`} className="mb-3" path={llwybr + '/cyfryngau'} accept="llun,sain,fideo"
			arNewid={ffeiliau => updateDoc(cais.ref, {cyfryngau: ffeiliau?.length || 0})} golygu={true}>
			<Trans>Llwythwch unrhyw ffeiliau sain, fideo, lluniau neu gyflwyniadau sy’n rhan o’ch perfformiad, os yn berthnasol.</Trans>
		</MaesFfeiliau>

		<Botwm/>
	</Bloc>
	);
});

export default Technegol;