import { Trans, t } from '@lingui/macro'
import Prysur from '../darnau/Prysur';
import Bloc from './Bloc';
import MaesFfeiliau from '../darnau/MaesFfeiliau';
import MaesTestun from '../darnau/MaesTestun';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useUser } from 'reactfire';
import { useBlwyddyn } from '../lib/ApBlaen';

const PlacCelf = forwardRef(function PlacCelf({cais, blociau, setBlociau, nesaf}, ref) {

	const blwyddyn = useBlwyddyn();
	const bloc = 'plac';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const {status: userStatus, data: user} = useUser();
	const [nifer, setNifer] = useState(0);

	function arAnfon(e) {
		e.preventDefault();
		cadwFfurflen(e.target);
		nesaf();
	}

	const cadwFfurflen = useCallback(() => {
		if (nifer !== blociau[bloc]) {
			const data = {};
			data[`gofynion.${bloc}`] = nifer;
			updateDoc(cais.ref, data);
			blociau[bloc] = nifer;
			setBlociau({...blociau});
		}
		return true;
	}, [nifer, cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen();
			},
		};
	}, [cadwFfurflen]);

	function arNewidFfeiliau(ffeiliau) {
		const nifer = ffeiliau ? ffeiliau.length : 0;
		setNifer(nifer);
		cadwFfurflen();
	}

	if (userStatus === 'loading') return <Prysur/>;
	const llwybr = `${blwyddyn}/cystadleuwyr/${user.uid}/${cais.id}/celf`;

	return (
		<Bloc label={ t`Plac Teilyngdod` } eventKey={bloc} statws={statws} onSubmit={arAnfon}>

			<p><Trans>Llwythwch chwe delwedd neu fideo o’r gwaith. Mae hi’n bwysig eich bod yn cyflwyno delweddau clir o’ch gwaith, gan sicrhau fod y gwaith ar gael rhwng 1af Ebrill i’r 16eg Awst 2024. Rhaid i’r gwaith fod wedi ei gwblhau ers 31ain Awst 2022, neu yn waith newydd wedi’i ddylunio’n arbennig ar gyfer yr ŵyl.</Trans></p>

			<MaesFfeiliau label={t`Eich gwaith`} path={llwybr} accept="dogfen,sain,llun,fideo" arNewid={arNewidFfeiliau} golygu={true}>
				<Trans>Delweddau fformat JPEG neu PNG, o leiaf 1000 picsel bob ochr. Fideo fformat MP4/M4V/MOV hyd at 10Mbps.</Trans>
			</MaesFfeiliau>

			<h3><Trans>Y prosiect</Trans></h3>

			<Trans><p>Rhowch y wybodaeth ganlynol:</p>
<ul>
<li>Enw’r prosiect</li>
<li>Enw pensaer y prosiect</li>
<li>Arwynebedd mewnol gros mewn metrau sgwâr, ac yn achos prosiectau dylunio tirwedd/trefol yn bennaf, arwynebedd allanol gros mewn metrau sgwâr</li>
<li>Arwynebedd mewnol net mewn metrau sgwâr</li>
<li>Gwerth y contract/cost adeiladu’r prosiect gan gynnwys adeiladu a dodrefnu os caiff ei wneud/goruchwylio gan y pensaer (ac eithrio costau tir a ffioedd)</li>
<li>Math o gytundeb (ar gyfer prosiectau yn y DU), dyddiad cymeradwyo cynllunio a dyddiad meddiannu’r prosiect</li>
<li>Enw a chyfeiriaid practis pensaer/stiwdio</li>
<li>Manylion cyswllt rheolwr prosiect/pensaer, cleient a chontractwr</li>
<li>Manylion cyswllt ar gyfer ymweliadau gan y detholwyr</li>
</ul>
<p>Os yn berthnasol, dylech sicrhau cytundeb rhwng yr holl randdeiliaid ar sut y dylid credydu’r prosiect os yn gweithio ar y cyd â chwmni neu bensaer arall.</p>
</Trans>

			<MaesTestun name="datganiad" defaultValue={ cais.datganiad } type="textarea" label={ t`Y prosiect` } rows="10">
				<Trans>Hyd at {1000} o nodau.</Trans>
			</MaesTestun>

			<h3><Trans>Disgrifiad o’r prosiect</Trans></h3>

			<Trans><p>Rhowch y wybodaeth ganlynol:</p>
<ul>
<li>Brîff gan y cleient</li>
<li>Lleoliad, safle neu gyfyngiadau perthnasol (byddwn yn gwneud pob ymdrech i beidio â chyflwyno gwedd negyddol i gynllunio)</li>
<li>Ymateb y pensaer i’r brîff, y deunyddiau a dull adeiladu</li>
<li>Crynodeb o’r amserlen</li>
<li>Cyfyngiadau parthed y gyllideb a’r rhaglen adeiladu</li>
<li>Datganiad byr yn crynhoi sut mae’r prosiect yn cyfrannu at y gymuned ac yn ymateb i egwyddorion dylunio cynhwysol, lle bo’n berthnasol</li>
</ul>
<p>Cyflwynir crynodeb o’r atebion gerbron y panel detholwyr, felly sicrhewch eich bod yn cyflwyno digon o wybodaeth am y prosiect.</p>
</Trans>

			<MaesTestun name="disgrifiad" defaultValue={ cais.disgrifiad } type="textarea" label={ t`Disgrifiad o’r prosiect` } rows="10">
				<Trans>Hyd at {500} o nodau.</Trans>
			</MaesTestun>

			<h3><Trans>Ymgynghorwyr allweddol</Trans></h3>

			<p><Trans>Cyfeiriwch at holl ymgynghorwyr allweddol y prosiect: peirianydd/peirianwyr strwythurol, peiriannydd/peirianwyr gwasanaethau, pensaer/penseiri tirwedd ac ati, gyda’u manylion cyswllt. Cynabyddir yr ymgynghorwyr mewn datganiadau i’r wasg ac ar dystysgrifau gwobrau.</Trans></p>

			<MaesTestun name="ymgynghorwyr" defaultValue={ cais.ymgynghorwyr } type="textarea" label={ t`Ymgynghorwyr allweddol` } rows="10">
				<Trans>Hyd at {200} o nodau.</Trans>
			</MaesTestun>

			<h3><Trans>Delweddau</Trans></h3>

			<Trans><p>Mae’n ofynnol i ymgeiswyr gyflwyno 8 delwedd:</p>
<ul>
<li>Cymysgedd o luniau allanol a mewnol o’r prosiect</li>
<li>Lluniau sy’n cyfleu’r ffordd y mae’r prosiect yn ymwneud â’i gyd-destun, gan fod y delweddau hyn yn cael eu defnyddio at ddibenion beirniadu</li>
<li>Lluniau llydan a lluniau agos sy’n crynhoi elfennau o-ddydd-i-ddydd y prosiect</li>
<li>Cynllun lleoliad (yn dangos y prosiect yn ei gyd-destun, ee 1:1250)</li>
<li>Cynllun safle</li>
<li>Cynllun llawr gwaelod (yn dangos y brif fynedfa)</li>
<li>Cynllun llawr nodweddiadol</li>
<li>Peidiwch â chynnwys logos cwmni na thestun ar unrhyw ddelweddau</li>
<li>Cofiwch gydnabod awdur pob ffotograff</li>
</ul></Trans>

			<MaesFfeiliau label={t`Delweddau`} path={llwybr} accept="llun,fideo" arNewid={arNewidFfeiliau} golygu={true}>
				<Trans>Delweddau fformat JPEG neu PNG, o leiaf 1000 picsel bob ochr.</Trans>
			</MaesFfeiliau>

			<Botwm/>
		</Bloc>
	);

});

export default PlacCelf;