import { Trans, t } from '@lingui/macro'
import Prysur from '../darnau/Prysur';
import Bloc from './Bloc';
import MaesFfeiliau from '../darnau/MaesFfeiliau';
import MaesTestun from '../darnau/MaesTestun';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { useState, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useUser } from 'reactfire';
import { glanhauTestun } from '../cyffredinol';
import { useBlwyddyn } from '../lib/ApBlaen';

const Celf = forwardRef(function Celf({cais, blociau, setBlociau, nesaf}, ref) {

	const blwyddyn = useBlwyddyn();
	const bloc = 'celf';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
    const {status: userStatus, data: user} = useUser();
	const [nifer, setNifer] = useState(0);
	const formRef = useRef(null);

	function arAnfon(e) {
		e.preventDefault();
		cadwFfurflen(e.target);
		nesaf();
	}

	const cadwFfurflen = useCallback((form) => {
		const formData = new FormData(form);
		const data = {};
		for (const key of ['datganiad', 'gwerth']) {
			if (formData.has(key)) {
				const val = glanhauTestun(formData.get(key));
				if (val !== cais[val]) {
					data[key] = val;
				}
			}
		}
		const cwblhawyd = nifer && form.checkValidity() ? nifer : false;
		if (cwblhawyd !== !!blociau[bloc]) {
			data[`gofynion.${bloc}`] = cwblhawyd;
			blociau[bloc] = cwblhawyd;
			setBlociau({...blociau});
		}
		if (Object.keys(data).length) {
			updateDoc(cais.ref, data);
		}
		return true;
	}, [nifer, cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	function arNewidFfeiliau(ffeiliau) {
		const nifer = ffeiliau ? ffeiliau.length : 0;
		setNifer(nifer);
		cadwFfurflen(formRef.current);
	}
	function arLwythoFfeiliau(ffeiliau) {
		const nifer = ffeiliau ? ffeiliau.length : 0;
		setNifer(nifer);
	}

	if (userStatus === 'loading') return <Prysur/>;
	const llwybr = `${blwyddyn}/cystadleuwyr/${user.uid}/${cais.id}/celf`;

	return (
		<Bloc label={ t`Celf` } formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>

			<p><Trans>Llwythwch chwe delwedd neu fideo o’r gwaith. Mae hi’n bwysig eich bod yn cyflwyno delweddau clir o’ch gwaith, gan sicrhau fod y gwaith ar gael rhwng 1af Ebrill i’r 16eg Awst 2024. Rhaid i’r gwaith fod wedi ei gwblhau ers 31ain Awst 2022, neu yn waith newydd wedi’i ddylunio’n arbennig ar gyfer yr ŵyl.</Trans></p>

			<MaesFfeiliau label={t`Eich gwaith`} path={llwybr} accept="dogfen,sain,llun,fideo" arNewid={arNewidFfeiliau} arLwytho={arLwythoFfeiliau} golygu={true} className="mb-3">
				<Trans>Delweddau fformat JPEG neu PNG, o leiaf 1000 picsel bob ochr. Fideo fformat MP4/M4V/MOV hyd at 10Mbps.</Trans>
			</MaesFfeiliau>

			<MaesTestun required name="datganiad" defaultValue={ cais.datganiad } type="textarea" label={ t`Datganiad artist` } rows="10">
				<Trans>Datganiad hyd at {300} o nodau ynglŷn â’r gwaith a gyflwynir (hynny yw, nid bywgraffiad artist, ond datganiad am y gwaith).</Trans>
			</MaesTestun>

			<MaesTestun required name="gwerth" defaultValue={ cais.gwerth } type="textarea" label={ t`Gwerth y gwaith` } rows="5">
				<Trans>Dylid nodi pris unrhyw waith sydd ar werth wrth gofrestru. Codir comisiwn o 40% (gan gynnwys TAW) ar unrhyw waith a werthir yn ystod wythos yr Eisteddfod.</Trans>
			</MaesTestun>

			<Botwm/>
		</Bloc>
	);

});

export default Celf;

// function MaesFfeiliauCelf({label, path, golygu, className}) {
//
// }
//
// function MaesFfeilCelf({ffeil}) {
//
// }