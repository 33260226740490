import { Trans } from '@lingui/macro'
import Table from 'react-bootstrap/Table';
import { Dyddiad, enwId} from '../cyffredinol';
import Rhif from '../darnau/Rhif';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { caelCasgliad } from '../cyffredinol';
import { query, where, documentId } from 'firebase/firestore';
import EnwCofnod from '../darnau/EnwCofnod';
// import { useBlwyddyn } from '../lib/ApBlaen';

export default function RhestrSlotiau({slotiau, dydd, llwyfan, artist}) {
	// const blwyddyn = useBlwyddyn();

	// const llwyfannau = new Set(slotiau.map(slot => slot.llwyfan));
	const llwyfannau = Array.from(slotiau.reduce((set, slot) => {
		set.add(slot.lle);
		set.add(slot.llwyfan);
		return set;
	}, new Set()));
	if (!llwyfannau.length) llwyfannau.push('xxx'); // TWDW

	const firestore = useFirestore();
	const {status, data:llefydd} = useFirestoreCollectionData(query(
		caelCasgliad(firestore, 'llefydd'),
		// where('dangos', 'in', ['barod', 'golygwyd', 'cyhoeddus']),
		where(documentId(), 'in', llwyfannau),
	), {idField: 'id'});

	if (status === 'loading') return;

	if (!slotiau || !slotiau.length) return;
	return (
		<Table hover>
			<tbody>{
			slotiau.map((slot) => {
				if (slot.dangos === 'dileu') return '';
				return (
					<tr key={slot.id} className={'dangos-' + slot.dangos}>
						<th className="fw-normal">
							{ slot.amser_galwad && <div>
								<b>{slot.amser_galwad}</b> (<Trans>galwad artist</Trans>)
							</div> }
							{ slot.amser && <div>
								<b>{slot.amser}</b>{slot.amser_galwad && <> (<Trans>perfformiad</Trans>)</>}
							</div> }
							{dydd ? '' : <div className="fs-6 text-secondary"><Dyddiad dyddiad={new Date(slot.dydd)} fformat="EEEE d MMMM"/></div>}
						</th>
						<td>
							{slot.mathau.includes('ymarfer') && <i><Trans>Ymarfer:</Trans> </i>}
							<EnwCofnod eitem={slot}/>
							{slot.rhifau ? slot.rhifau.map(rhif => <Rhif key={rhif} rhif={rhif}/>) : ''}
							{slot.llwyfan === llwyfan ? '' : <div className="fs-6 text-secondary">{enwId(llefydd, slot.llwyfan || slot.lle)}</div>}
						</td>
					</tr>
				);
			})
			}
			</tbody>
		</Table>
	);
}