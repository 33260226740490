import { useState, useId } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Card from 'react-bootstrap/Card';
import { t } from '@lingui/macro'
import { ChevronRight, ChevronDown } from 'react-bootstrap-icons';

export default function Datgelu({label, children}) {
	const [open, setOpen] = useState(false);
	const id = useId();

	return (
		<>
		<Button onClick={() => setOpen(!open)} aria-expanded={open} aria-controls={id} active={open} className="mb-3">
			{ open ? <ChevronDown/> : <ChevronRight/> }
			<span>{ label || t`Dangos` }</span>
		</Button>
		<Collapse in={open}>
			<div id={id}>
				<Card body className="mb-3">{children}</Card>
			</div>
		</Collapse>
		</>
	);
}