import { Trans, t } from '@lingui/macro'
// import Prysur from '../darnau/Prysur';
import Bloc from './Bloc';
import MaesFfeiliau from '../darnau/MaesFfeiliau';
import MaesTicio from '../darnau/MaesTicio';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Link } from 'react-router-dom';

const Darnau = forwardRef(function Darnau({cais, llwybr, blociau, setBlociau, nesaf}, ref) {

	const bloc = 'darnau';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const formRef = useRef(null);
	const [nifer, setNifer] = useState(0);

	function arAnfon(e) {
		e.preventDefault();
		if (!e.target.checkValidity()) {
			e.target.reportValidity();
		} else if (cadwFfurflen(e.target)) {
			nesaf();
		}
	}

	const cadwFfurflen = useCallback((form) => {
		form ??= formRef.current;
		const cwblhawyd = form?.checkValidity() ? nifer : false;
		if (cwblhawyd !== blociau[bloc]) {
			const data = {};
			data[`gofynion.${bloc}`] = cwblhawyd;
			updateDoc(cais.ref, data);
			blociau[bloc] = cwblhawyd;
			setBlociau({...blociau});
		}
		return true;
	}, [nifer, cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	function arNewidFfeiliau(ffeiliau) {
		setNifer(ffeiliau?.length || 0);
		cadwFfurflen(formRef.current);
	}

	function arLwythoFfeiliau(ffeiliau) {
		setNifer(ffeiliau?.length || 0);
	}

	return (
		<Bloc label={ t`Darnau` } formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>
			<MaesFfeiliau label={t`Llwytho eich darnau`} className="mb-3" path={llwybr + '/darnau'} accept="dogfen,sain,fideo"
				arLwytho={arLwythoFfeiliau} arNewid={arNewidFfeiliau} golygu={true}>
				<Trans>Ychwanegwch eich darnau, ffeiliau sain, neu fideo, yn ôl gofynion y gystadleuaeth.</Trans>
			</MaesFfeiliau>
			<MaesTicio required defaultChecked={!!blociau[bloc]} label={t`Rwy’n cadarnhau bod hawl i berfformio’r darn neu ddarnau yn gyhoeddus yn yr Eisteddfod.`} name="hawlfraint"/>
			<p><Trans>Darllenwch ragor am <Link to="/cymorth/hawlfraint">drefnu hawlfraint ar gyfer perfformiad</Link>.</Trans></p>
			<Botwm/>
		</Bloc>
	);

});

export default Darnau;