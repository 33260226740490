import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { Trans, t } from '@lingui/macro'

function MaesTestun({label, sublabel, type, children, onChange, gwall, gair, ...props}) {
	const [geiriau, setGeiriau] = useState(gair && cyfriGeiriau(props.defaultValue));
	const [gormod, setGormod] = useState(false);
	const [nodyn, setNodyn] = useState('');

	function cyfriGeiriau(t) {
		t = t?.trim() || '';
		return t ? t.split(/\s+/).length : 0;
	}

	function arNewid(e) {
		const validity = e.currentTarget.validity;
		if (gwall) {
			setNodyn(validity.valid ? '' : gwall);
		}
		if (gair) {
			const nifer = cyfriGeiriau(e.currentTarget.value);
			if (nifer > gair) {
				e.currentTarget.setCustomValidity(t`Mae’r testun yn rhy hir.`);
			} else {
				e.currentTarget.setCustomValidity('');
			}
			setGeiriau(nifer);
		}
	}

	return (
		<Form.Group className="mb-3">
			{ gair &&
				<span className={geiriau > gair ? 'text-danger float-end' : 'text-secondary float-end'}>
					{ geiriau ? <Trans>{geiriau} o {gair} gair</Trans> : <Trans>hyd at {gair} gair</Trans> }
				</span>
			}
			<Form.Label>{ label }{
				sublabel && <span className="text-secondary fw-normal"> {sublabel}</span>
			}</Form.Label>
			{ children && <div className="form-text text-body-secondary">{ children }</div> }
			<Form.Control
				type={ type === 'textarea' ? null : (type || 'text') }
				as={ type === 'textarea' ? 'textarea' : 'input' }
				onChange={(gair || gwall) && arNewid}
				{...props}/>
			{ nodyn && <div className="form-text text-danger">{ nodyn }</div> }
		</Form.Group>
	);
}
				/* name={ props.name } defaultValue={ props.defaultValue }
				placeholder={props.placeholder} pattern={props.pattern}
				disabled={!!props.disabled} required={!!props.required}
				size={props.size} rows={props.rows} */

export default MaesTestun;