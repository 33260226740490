import Spinner from 'react-bootstrap/Spinner';
import { Trans } from '@lingui/macro'

function Prysur() {
	return (
		<Spinner animation="border" variant="secondary" role="status">
			<span className="visually-hidden"><Trans>Llwytho…</Trans></span>
		</Spinner>
	);
}

export default Prysur;