import { Trans, t } from '@lingui/macro'
import Bloc from './Bloc';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { glanhauTestun } from '../cyffredinol';
import { forwardRef, useRef, useImperativeHandle, useCallback } from 'react';
import MaesTestun from '../darnau/MaesTestun';
import { LinkContainer } from 'react-router-bootstrap'
import Button from 'react-bootstrap/Button';

const Mynediad = forwardRef(function Mynediad({cais, slot, lle, llwybr, blociau, setBlociau, nesaf}, ref) {

	const bloc = 'mynediad';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const formRef = useRef(null);

	const disabled = !!(cais.ceir?.[0]?.id || cais.ceir?.[1]?.id);

	function arAnfon(e) {
		e.preventDefault();
		if (disabled) {
			nesaf();
		} else if (!e.target.checkValidity()) {
			e.target.reportValidity();
		} else if (cadwFfurflen(e.target)) {
			nesaf();
		}
	}

	const cadwFfurflen = useCallback((form) => {
		const formData = new FormData(form);
		const rhifau = formData.getAll('rhif').map(glanhauTestun);
		const pwrpasau = formData.getAll('pwrpas').map(glanhauTestun);
		const data = {};
		const ceir = [];
		let i = 0;
		// for (const rhif of rhifau) {
		// 	if (!rhif) continue;
		// 	ceir.push({rhif, pwrpas: pwrpas[i] || ''});
		// 	i++;
		// }
		for (const pwrpas of pwrpasau) {
			if (pwrpas) {
				const car = { pwrpas };
				if (rhifau[i]) car.rhif = rhifau[i];
				ceir.push(car);
			}
			i++;
		}
		if (ceir.length) data.ceir = ceir;
		const cwblhawyd = form.checkValidity();
		if (cwblhawyd !== !!blociau[bloc]) {
			data[`gofynion.${bloc}`] = cwblhawyd;
			blociau[bloc] = cwblhawyd;
			setBlociau({...blociau});
		}
		if (Object.keys(data).length) {
			updateDoc(cais.ref, data);
		}
		return true;
	}, [cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	return (
	<Bloc disabled={disabled} label={t`Cerbydau ac offer`} formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>

		<h4 className="fs-5 mb-3"><Trans>Cludo offer trwm</Trans></h4>
		<p><Trans>Bydd trolis pwrpasol ar gael ar y maes i gludo offer trwm o’r brif fynedfa i’r llwyfannau. Os hoffech ddefnyddio un o’r rhain, gofynnwch wrth staff y fynedfa neu ffoniwch rheolwr eich llwyfan wrth i chi gyrraedd i drefnu.</Trans></p>

		<h4 className="fs-5 mb-3"><Trans>Pàs car</Trans></h4>
		<p><Trans>Os bydd offer trwm gennych <b>nad yw’n bosib ei gario,</b> a bod angen dod â cherbyd i’r maes, mi fydd angen gwneud cais am bàs car. Oherwydd rheolau iechyd a diogelwch dim ond cerbydau angenrheidiol fydd yn cael mynediad i’r maes.</Trans></p>
		<p><Trans>Nodwch nad oes angen pàs car am resymau hygyrchedd – os oes gennych chi fathodyn glas, dilynwch yr arwyddion i’r maes parcio priodol.</Trans></p>
		<p><Trans>Byddwn yn gwirio pob cais ac yn ebostio cadarnhad atoch chi yn ystod yr wythnos cyn yr Eisteddfod, gyda chyfarwyddiadau i lawrlwytho ac argraffu’r pàs. Ni fydd y criw diogelwch yn caniatáu mynediad i unrhyw gerbyd heb y pàs angenrheidiol yn y ffenest.</Trans></p>

		<MaesNeuFotwmCerbyd cerbyd={cais.ceir?.[0]}
			label={ t`Cais am bàs car` }
			cymorth={ t`Nodwch y rhesymau dros eich cais am bàs car (neu gadewch yn wag).` }
			disabled={disabled}/>

		<MaesNeuFotwmCerbyd cerbyd={cais.ceir?.[1]}
			label={ t`Cais am ail bàs car` }
			cymorth={ t`Nodwch y rhesymau dros eich cais am ail bàs car, os oes angen un (neu gadewch yn wag).` }
			disabled={disabled}/>

{/*
		<div className="row">
			<div className="col">
				<label className="form-label" htmlFor="rhif_car"><Trans>Rhif cofrestru’r car</Trans></label>
			</div>
			<div className="col">
				<label className="form-label" htmlFor="pwrpas_car"><Trans>Rheswm am y pàs</Trans></label>
			</div>
		</div>
		<div className="row mb-3">
			<div className="col">
				<input defaultValue={cais.ceir?.[0]?.rhif} type="text" className="form-control" id="rhif_car" name="rhif" autoComplete="off"/>
			</div>
			<div className="col">
				<input defaultValue={cais.ceir?.[0]?.pwrpas} type="text" className="form-control" id="pwrpas_car" name="pwrpas" autoComplete="off"/>
			</div>
		</div>
		<div className="row mb-3">
			<div className="col">
				<input defaultValue={cais.ceir?.[1]?.rhif} type="text" className="form-control" name="rhif" autoComplete="off"/>
			</div>
			<div className="col">
				<input defaultValue={cais.ceir?.[1]?.pwrpas} type="text" className="form-control" name="pwrpas" autoComplete="off"/>
			</div>
		</div>
*/}
		<Botwm/>
	</Bloc>
	);
});

function MaesNeuFotwmCerbyd({cerbyd, label, cymorth, disabled}) {
	if (cerbyd?.id) {
		return (
		<div className="mb-3">
			<LinkContainer to={'/cerbyd/' + cerbyd.id}>
				<Button variant="primary" size="md" as="a">
					Pas cerbyd
				</Button>
			</LinkContainer>
		</div>
		);
	} else {
		return (
		<MaesTestun name="pwrpas" type="textarea" label={label}
			defaultValue={cerbyd?.pwrpas}
			disabled={disabled}>
			{cymorth}
		</MaesTestun>
		);
	}
}

export default Mynediad;