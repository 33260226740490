import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApBlaen } from "./lib/ApBlaen";
import { FirebaseAppProvider, StorageProvider, FirestoreProvider, AuthProvider, useFirebaseApp } from 'reactfire';
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { Root } from "./routes/root";
import ErrorPage from "./error";
import { Cofrestru } from "./routes/ceisiadau";
import { Cais } from "./routes/cais";
import { Slotiau } from "./routes/slotiau";
import { Slot, slotLoader, slotAction } from "./routes/slot";
import * as Sentry from "@sentry/react";
// import './index.css';

const safleByw = process.env.NODE_ENV === "production";

const firebaseConfig = {
	apiKey: "AIzaSyC8W0VDPeOnTq6d3d55CsvgFxr4HBZ5baY",
	authDomain: "e-steddfod.firebaseapp.com",
	projectId: "e-steddfod",
	storageBucket: "e-steddfod.appspot.com",
	messagingSenderId: "634073330231",
	appId: "1:634073330231:web:344806f92cfd89e6fb85b2"
};

if (safleByw) {
	console.debug = () => {}
	Sentry.init({
		dsn: "https://f9c37d271d5d0ff354649a9307ff53d6@o4507382895345664.ingest.de.sentry.io/4507577489162320",
		environment: process.env.NODE_ENV,
		// integrations: [
		// 	Sentry.browserTracingIntegration(),
		// 	Sentry.replayIntegration(),
		// ],
		// Performance Monitoring
		// tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

// ReactDOM.createRoot(document.getElementById('root')).render(
// 	<FirebaseAppProvider firebaseConfig={firebaseConfig}>
// 		<FirebaseApp />
// 	</FirebaseAppProvider>
// );

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<FirebaseApp />
		</FirebaseAppProvider>
	</React.StrictMode>
);

function idLoader({params}) {
	return params.id;
}

function FirebaseApp(props) {

    // const { status, data: firestore } = useInitFirestore(async (firebaseApp) => {
	// 	const db = initializeFirestore(firebaseApp, {});
	// 	await enableIndexedDbPersistence(db);
	// 	return db;
	// });

	const app = useFirebaseApp();
	const firestore = getFirestore(app);
	const auth = getAuth(app);
	const storage = getStorage(app);

	// if (status === 'loading') return;

	// Check for dev/test mode however your app tracks that.
	if (!safleByw) {
		connectStorageEmulator(storage, 'localhost', 9199);
		connectFirestoreEmulator(firestore, 'localhost', 8081);
		connectAuthEmulator(auth, 'http://localhost:9099');
	}

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Root />,
			errorElement: <ErrorPage />,
			children: [
				// {
				// 	path: "/mewngofnodi",
				// 	element: <Mewngofnodi/>,
				// 	errorElement: <ErrorPage />,
				// },
				{
					path: "",
					element: <Cofrestru />,
				},
				{
					path: "cais/:id",
					element: <Cais />,
					loader: idLoader,
				},
				{
					path: "perfformio",
					lazy: () => import('./routes/perfformiadau'),
				},
				{
					path: "perfformio/:id",
					lazy: () => import('./routes/perfformiad'),
				},
				{
					path: "beirniadu",
					lazy: () => import('./routes/beirniadaethau'),
				},
				{
					path: "beirniadu/:id",
					async lazy() {
						const { action, loader, Component } = await import("./routes/beirniadaeth");
						return {
							action: (args) => action({firestore, auth, ...args}),
							loader: (args) => loader({firestore, auth, ...args}),
							Component
						};
					},
				},
				{
					path: "cyfeilio",
					lazy: () => import('./routes/beirniadaethau'),
				},
				{
					path: "cyfeilio/:id",
					async lazy() {
						const { action, loader, Component } = await import("./routes/beirniadaeth");
						return {
							action: (args) => action({firestore, auth, ...args}),
							loader: (args) => loader({firestore, auth, ...args}),
							Component
						};
					},
				},
				{
					path: "digwyddiadau",
					element: <Slotiau />,
				},
				{
					path: "digwyddiadau/:id",
					element: <Slot />,
					action: (args) => slotAction({firestore, auth, ...args}),
					loader: (args) => slotLoader({firestore, auth, ...args}),
				},
				{
					path: "cymorth/:id?",
					lazy: () => import('./routes/cymorth'),
				},
				{
					path: "tudalen/:id",
					lazy: () => import('./routes/tudalen'),
				},
				{
					path: "proffil",
					lazy: () => import('./routes/proffil'),
				},
				{
					path: "cerbyd/:id?",
					lazy: () => import('./routes/cerbydau'),
				},
			],
		}
	], {
		basename: "/cofrestru"
	});

	return (
		<AuthProvider sdk={auth}>
			<FirestoreProvider sdk={firestore}>
				<StorageProvider sdk={storage}>
					<ApBlaen>
						<RouterProvider router={router} />
					</ApBlaen>
				</StorageProvider>
			</FirestoreProvider>
		</AuthProvider>
	);

};
