import { useFirestore } from 'reactfire';
import { caelCasgliad } from '../cyffredinol';
import { useFirestoreCollectionData } from 'reactfire';
import { query, where } from 'firebase/firestore';
import { Trans } from '@lingui/macro'
import Prysur from '../darnau/Prysur';
import { usePersonCyfredol } from '../lib/ApBlaen';
import CerdynSlot from '../darnau/CerdynSlot';

/* === Slotiau fel Trefnydd === */

export function Slotiau() {
	const {status, data:person} = usePersonCyfredol();

	return (
		<>
		<h1 className="mb-4"><Trans>Digwyddiadau</Trans></h1>
		{
			(status === 'loading' || !person)
				? <p><Trans>Dim digwyddiadau.</Trans></p>
				: <CerdiauSlotiau person={person}/>
		}
		</>
	)

}

function CerdiauSlotiau({person}) {
	const casgliad = caelCasgliad(useFirestore(), 'amserlen');
	const ymholiad = query(casgliad,
		where(`_pobl.${person.id}.math`, '==', 'trefnydd'),
		// where('dangos', '!=', 'dileu')
	);
	//const ymholiad = query(casgliad, orderBy(`_pobl.${person.id}`));

	let {status, data} = useFirestoreCollectionData(ymholiad, {idField: 'id'});
	if (status === 'loading') {
		return <Prysur/>;
	}

	data = data.filter(eitem => {
		if (eitem._rhiant || !['drafft', 'barod', 'golygwyd', 'cyhoeddus'].includes(eitem.dangos)) {
			return false;
		}
		return true;
	});
	data.sort((a,b) => {
		return a.dechrau.toDate() - b.dechrau.toDate();
	});

	return (
		<div>
		{data.length
			? data.map((eitem) => {
				const golygu = ['drafft', 'barod'].includes(eitem.dangos);
				return <CerdynSlot slot={eitem} key={eitem.id} golygu={golygu}/>
			})
			: <p><Trans>Dim digwyddiadau.</Trans></p>
		}
		</div>
	);

}

