import { Trans, t } from '@lingui/macro'
import MaesTestun from '../darnau/MaesTestun';
import Bloc from './Bloc';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { glanhauTestun } from '../cyffredinol';
import { forwardRef, useRef, useImperativeHandle, useCallback } from 'react';

const Dewisiad = forwardRef(function Dewisiad({cais, blociau, setBlociau, nesaf}, ref) {

	const bloc = 'dewisiad';
	const isflociau = ['dewisiad', 'cyweirnod', 'offeryn'];
	const formRef = useRef(null);

	const statws = ( ('dewisiad' in blociau && !blociau.dewisiad)
		|| ('cyweirnod' in blociau && !blociau.cyweirnod)
		|| ('offeryn' in blociau && !blociau.offeryn)
	) ? '' : 'cwblhawyd';

	function arAnfon(e) {
		e.preventDefault();
		if (!e.target.checkValidity()) {
			e.target.reportValidity();
		} else if (cadwFfurflen(e.target)) {
			nesaf();
		}
	}

	const cadwFfurflen = useCallback((form) => {
		const formData = new FormData(form);
		const data = {};
		for (const key of isflociau) {
			if (formData.has(key)) {
				const val = glanhauTestun(formData.get(key));
				if (val !== cais[val]) {
					data[key] = val;
				}
				const llenwyd = !!val;
				if (blociau[key] !== llenwyd) {
					blociau[key] = llenwyd;
					data[`gofynion.${key}`] = llenwyd;
				}
			}
		}
		if (Object.keys(data).length) {
			setBlociau({...blociau});
			updateDoc(cais.ref, data);
		}
		return true;
	}, [cais, isflociau, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	return (
	<Bloc label={t`Perfformiad`} formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>
		{ 'dewisiad' in blociau &&
			<MaesTestun name="dewisiad" defaultValue={ cais.dewisiad } type="textarea" label={ t`Eich dewisiad neu ddewisiadau` } required>
				<Trans>Nodwch enw’r darn neu darnau y byddwch yn eu perfformio.</Trans>
			</MaesTestun>
		}
		{ 'cyweirnod' in blociau &&
			<MaesTestun name="cyweirnod" defaultValue={ cais.cyweirnod } type="textarea" label={ t`Cyweirnod` } required>
				<Trans>Nodwch gyweirnod y darn neu darnau y byddwch yn eu perfformio.</Trans>
			</MaesTestun>
		}
		{ 'offeryn' in blociau &&
			<MaesTestun name="offeryn" defaultValue={ cais.offeryn } label={ t`Offeryn` } required>
				<Trans>Nodwch yr offeryn(nau) y bydd yn eu defnyddio.</Trans>
			</MaesTestun>
		}
		<Botwm/>
	</Bloc>
	);
});

export default Dewisiad;