import { useId } from 'react';
import Form from 'react-bootstrap/Form';

export default function MaesTicio(props) {
	const id = useId();
	return (
		<Form.Group className="mb-3" controlId={id}>
			<Form.Check type="checkbox" {...props} />
		</Form.Group>
	);
}

