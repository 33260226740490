import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Upload, FileEarmark } from 'react-bootstrap-icons';
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { useState, useEffect } from 'react';
import { useStorage } from 'reactfire';
import { Trans } from '@lingui/macro';

const mathau = {
	llun: ".jpg,.png,image/jpeg,image/png",
	dogfen: ".doc,.docx,.rtf,.txt,.pdf,.odt,.pages,text/*,application/pdf,application/rtf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text,application/vnd.apple.pages",
	sain: ".wav,.mp3,.m4a,audio/*",
	fideo: ".mp4,.mov,.m4v,.ppt,.pptx,.pdf,.key,video/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.apple.keynote",
};

export function MaesFfeiliau({ path, accept = '', golygu, label, name, arNewid, arLwytho, children, className }) {
	const [ffeiliau, setFfeiliau] = useState(null);
	const [cynnydd, setCynnydd] = useState(null);
	const storage = useStorage();

	useEffect(() => {
		const ffolder = ref(storage, path);
		listAll(ffolder).then((res) => {
			setFfeiliau(res.items);
			arLwytho && arLwytho(res.items);
		}).catch((error) => {
			// Uh-oh, an error occurred!
		});
	}, [storage, path, arLwytho]);

	// useEffect(() => {
	// 	if (arNewid) arNewid(ffeiliau);
	// }, [arNewid, ffeiliau]);

	function enwUnigryw(enw) {
		if (!ffeiliau) return enw;
		const enwau = [];
		for(const ffeil of ffeiliau) {
			enwau.push(ffeil.name);
		}
		while (enwau.includes(enw)) {
			enw = enw.replace(/(?: +(\d+))?(\.\w+)?$/, (m, m1, m2) => {
				return ' ' + (m1 ? +m1 + 1 : 2) + m2;
			}, enw);
		}
		return enw;
	}

	const arFewnbwn = (e) => {
		e.preventDefault();
		const files = e.target.files || e.dataTransfer.files;
		const file = files[0];
		if (!file) return;
		const enw = enwUnigryw(file.name);
		const storageRef = ref(storage, path + '/' + enw);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on("state_changed",
			(snapshot) => {
				const cynnydd = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 1000) / 10;
				setCynnydd(cynnydd);
			},
			(error) => {
				alert(error);
			},
			(success) => {
				//getDownloadURL(uploadTask.snapshot.ref).then(url => setUrl(url));
				setCynnydd(null);
				const ffeiliauNewydd = [...ffeiliau, uploadTask.snapshot.ref];
				setFfeiliau(ffeiliauNewydd);
				arNewid && arNewid(ffeiliauNewydd);
			}
		);
	}

	for (const math in mathau) {
		accept = accept.replace(math, mathau[math]);
	}

	return (
		<Form.Group className={className}>
			<Form.Label>{label}</Form.Label>
			{ children && <p>{children}</p> }
			<Ffeiliau ffeiliau={ffeiliau} setFfeiliau={setFfeiliau} golygu={golygu} arNewid={arNewid}>
				{cynnydd !== null &&
					<ListGroup.Item>
						<ProgressBar className="my-1" animated now={cynnydd} label={Math.round(cynnydd) + '%'} visuallyHidden />
					</ListGroup.Item>
				}
			</Ffeiliau>
			{ golygu ?
				<label>
					<span className="btn btn-primary"><Upload/> <span><Trans>Ychwanegu ffeil…</Trans></span></span>
					<input type="file" onInput={arFewnbwn} accept={accept} className="visually-hidden" />
				</label>
			: <p><Trans>Nid yw’n bosib ychwanegu ffeiliau bellach.</Trans></p>
			}
		</Form.Group>
	);
}

function Ffeiliau({ffeiliau, setFfeiliau, golygu, children, arNewid}) {

	function gwaredwyd(ffeil) {
		const index = ffeiliau.indexOf(ffeil);
		if (index > -1) {
			ffeiliau.splice(index, 1);
		}
		setFfeiliau([...ffeiliau]);
		arNewid && arNewid(ffeiliau);
	}

	return (
		<ListGroup>{ ffeiliau && ffeiliau.map((ref) => {
			return <Ffeil ffeil={ref} key={ref.name} gwaredwyd={gwaredwyd} golygu={golygu}/>;
		}) }{ children }
		</ListGroup>
	);
}

function Ffeil({ffeil, golygu, gwaredwyd}) {
	const [url, setUrl] = useState('');
	const [prysur, setPrysur] = useState(false);
	function dangos(e) {
		if (!url) {
			e.preventDefault();
			const ffenest = window.open(null, e.target.target);
			getDownloadURL(ffeil).then(url => {
				setUrl(url);
				ffenest.location = url;
			});
		}
	}
	function gwaredu(e) {
		e.preventDefault();
		setPrysur(true);
		deleteObject(ffeil).then(() => {
			gwaredwyd && gwaredwyd(ffeil);
		}).catch((error) => {
			console.error(error);
		});
	}
	return (
		<ListGroup.Item className="d-flex align-items-center">
			<FileEarmark className="me-1"/>
			<a href={url} onClick={dangos} target="_blank" rel="noreferrer">{ ffeil.name }</a>
			{ golygu &&
				<Button onClick={gwaredu} className="ms-auto" variant="secondary" size="sm">
				{ prysur
					? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
					: <Trans>Gwaredu</Trans>
				}
				</Button>
			}
		</ListGroup.Item>
	);
}

export default MaesFfeiliau;