import { Dyddiad } from '../cyffredinol';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons';
import { Trans, t, plural } from '@lingui/macro'

export default function CerdynSlot({slot, llefydd, golygu}) {
	// const { i18n } = useLingui();

	return (
		<Card className="mb-4">
			<Card.Body>
				<Card.Title>{ slot.enw }</Card.Title>
				<Card.Subtitle className="mb-2 text-body-secondary">
					<Dyddiad dyddiad={slot.dechrau.toDate()} fformat={t`d MMMM yyyy 'am' HH:mm`}/>
					{ slot._plant && ', ' + plural(slot._plant.length, {
						one: 'ac # amser arall',
						other: 'a # amser arall',
					}) }
				</Card.Subtitle>
				{ !golygu && <Card.Text>
					<Trans>Nid yw’n bosib golygu’r manylion hyn bellach.</Trans>
				</Card.Text> }
				<div className="d-flex">
					<LinkContainer to={slot.id}>
						<Button variant="primary" className="ms-auto"><span>{ golygu
							? <Trans>Golygu</Trans>
							: <Trans>Dangos</Trans>
						}</span> <ArrowRight/></Button>
					</LinkContainer>
				</div>
			</Card.Body>
		</Card>
	);
}
