import { doc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { Form, useLoaderData, redirect, useLocation } from 'react-router-dom';
import { gwraiddCasgliad, caelDogfen, Dyddiad, diweddaruCofnod } from '../cyffredinol';
import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import Prysur from '../darnau/Prysur';
import EnwCofnod from '../darnau/EnwCofnod';
import MaesHtml from '../ffurflen/MaesHtml';
import MaesTestun from '../darnau/MaesTestun';
import MaesTicio from '../darnau/MaesTicio';
import Datgelu from '../darnau/Datgelu';

import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { usePersonCyfredol } from '../lib/ApBlaen';

export async function slotLoader({firestore, params}) {
	const id = params.id;
	const slot = await caelDogfen(firestore, 'amserlen', id);
	if (!slot) throw new Response('Heb ganfod', { status: 404 });
	const llwyfan = await caelDogfen(firestore, 'llefydd', slot.llwyfan);
	return {slot, llwyfan};
}

export async function slotAction({firestore, auth, request, params}) {

	if (!params.id || request.method !== 'POST') {
		throw new Response("Annilys", { status: 400 });
	}

	const data = dataFfurflen(await request.formData());
	const docRef = doc(firestore, gwraiddCasgliad('amserlen'), params.id);
	const updatedRef = await diweddaruCofnod(docRef, data);

	if (updatedRef) {
		return redirect(`/digwyddiadau/${updatedRef.id}`);
	}
	return false; // dim gwallau

}

function dataFfurflen(formData) {
	const form = Object.fromEntries(formData.entries());
	const data = {};
	for (const key of ['trefnwyr', 'enw', 'enw_en', 'disgrifiad', 'disgrifiad_en', 'cynnwys', 'cynnwys_en']) {
		if (key in form) data[key] = form[key];
	}
	if (form.cyfieithu) data.cyfieithu = true;
	data.dangos = 'barod';
	return data;
}

export function Slot() {
	const {slot, llwyfan} = useLoaderData();
	const {status:statusPerson, data:person} = usePersonCyfredol();
	const {i18n} = useLingui();

	if (statusPerson === 'loading') {
		return <Prysur/>;
	}

	if (!person || !(person.id in slot._pobl) ) return 'Dim mynediad.';

	return (
		<>
		<h1 className="title"><EnwCofnod eitem={slot}/></h1>
		<h2 className="subtitle text-secondary">
			<Dyddiad dyddiad={slot.dechrau.toDate()} fformat={t`d MMMM yyyy 'am' HH:mm`}/>
			{' • '}<EnwCofnod eitem={llwyfan}/>
		</h2>
		<Datgelu label={t`Dangos manylion technegol`}>
			<div className="content" dangerouslySetInnerHTML={{ __html: (
				i18n.locale === 'en' && llwyfan._technegol_en ? llwyfan._technegol_en : llwyfan._technegol
			)}}/>
		</Datgelu>

		{/* <CynnwysCofnod eitem={cyst}/> */}
		<FfurflenSlot slot={slot}/>
		</>
	);

}

function FfurflenSlot({slot}) {
	const location = useLocation();
	const [stad, setStad] = useState(false);

	useEffect(() => {
		if (stad) setStad(false);
	}, [location]);

	const golygu = (slot.dangos === 'drafft' || slot.dangos === 'barod');//cyst.statws === 'beirniadu';

	return (
	<Form method="POST" disabled={!golygu}
		onChange={() => setStad('budur')} onSubmit={() => setStad('prysur')}
		className="mt-5"
	>
		{ golygu
			? <p><Trans>Rhowch fanylion eich sesiwn isod os gwelwch yn dda.</Trans></p>
			: <p><b><Trans>Nid yw’n bosib golygu’r manylion hyn bellach.</Trans></b></p>
		}
		<MaesTestun label={t`Trefnwyr`} sublabel="Enw eich mudiad neu sefydliad" name="trefnwyr" lang="cy" defaultValue={slot.trefnwyr} required={true}/>
<hr/>
		<MaesTestun label={t`Teitl`} sublabel={t`Cymraeg`} name="enw" lang="cy" defaultValue={slot.enw} required={true}/>
		<MaesTestun label={t`Siaradwyr/panel`} sublabel={t`Cymraeg`} name="disgrifiad" lang="cy" defaultValue={slot.disgrifiad}/>
		<MaesHtml label={t`Disgrifiad`} sublabel={t`Cymraeg`} name="cynnwys" lang="cy" defaultValue={slot.cynnwys} setStad={setStad} required={true}/>
<hr/>
		<MaesTestun label={t`Teitl`} sublabel={t`Saesneg`} name="enw_en" lang="en" defaultValue={slot.enw_en} required={true}/>
		<MaesTestun label={t`Siaradwyr/panel`} sublabel={t`Saesneg`} name="disgrifiad_en" lang="en" defaultValue={slot.disgrifiad_en}/>
		<MaesHtml label={t`Disgrifiad`} sublabel={t`Saesneg`} name="cynnwys_en" lang="en" defaultValue={slot.cynnwys_en} setStad={setStad} required={true}/>

		<MaesTicio label={t`Byddwn yn trefnu bod cyfieithu ar gael (gweler y manylion technegol uchod)`} name="cyfieithu" defaultChecked={slot.cyfieithu}/>

		<RhesBotymau stad={stad} golygu={golygu}/>
	</Form>
	);
}

function RhesBotymau({stad, golygu}) {
	return (
		<div className="d-flex justify-content-end align-items-center">
			{stad === 'prysur' && <Spinner animation="border" variant="secondary" role="status" aria-hidden="true" className="me-2"/>}
			<Button name="cadw" type="submit" variant="primary" disabled={stad !== 'budur' || !golygu}>Cadw</Button>
		</div>
	);
}

export function Botwm() {
	return (
		<div className="d-flex">
			<Button variant="primary" type="submit" className="px-4"><span><Trans>Cadw</Trans></span></Button>
		</div>
	);
}

