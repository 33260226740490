import { Trans } from '@lingui/macro'
import { Link, redirect, useLocation } from "react-router-dom";
import { useAuth, useUser } from 'reactfire';
import { useRolau } from '../lib/ApBlaen';

import { BoxArrowInRight, BoxArrowRight, QuestionCircle } from 'react-bootstrap-icons';
import NewidIaith from '../darnau/NewidIaith';
import logo from '../media/eisteddfod.svg';

export function Pennyn() {
	return (
		<>
		<nav className="navbar navbar-light navbar-expand-sm d-print-none">
			<div className="container container-max-md">
				<Link className="navbar-brand" to="/">
					<img src={ logo } alt="Eisteddfod" width="125" height="21"/>
				</Link>
				<div className="navbar-nav ms-auto flex-row">
					<div className="navbar-item me-3">
						<BotwmCymorth/>
					</div>
					<NewidIaith className="navbar-item"/>
					<div className="navbar-item ms-3">
						<BotwmMewngofnodi/>
					</div>
				</div>
			</div>
		</nav>
		<Pennyn2/>
		</>
	);
}

function Pennyn2({person}) {
	const rolau = useRolau();
	const location = useLocation();
	if (!rolau) return (
		<div className="container container-max-md d-print-none mt-2 mb-3"/>
	);

	const beirniad = rolau?.includes('beirniad');
	const cyfeilydd = rolau?.includes('cyfeilydd');

	return(
		<div className="container container-max-md d-print-none mt-2 mb-3">
			<div className={'btn-group'} role="group">
			{ rolau?.includes('cystadleuydd') &&
				<Link to="/" className={ location.pathname === '/' || location.pathname.startsWith('/cais') ? 'btn btn-primary' : 'btn btn-outline-primary' }>
					<Trans>Cystadlu</Trans>
				</Link>
			}
			{ rolau?.includes('artist') &&
				<Link to="/perfformio" className={ location.pathname.startsWith('/perfformio') ? 'btn btn-primary' : 'btn btn-outline-primary' }>
					<Trans>Perfformio</Trans>
				</Link>
			}
			{ rolau?.includes('trefnydd') &&
				<Link to="/digwyddiadau" className={ location.pathname.startsWith('/digwyddiadau') ? 'btn btn-primary' : 'btn btn-outline-primary' }>
					<Trans>Digwyddiadau</Trans>
				</Link>
			}
			{ beirniad &&
				<Link to="/beirniadu" className={ location.pathname.startsWith('/beirniadu') ? 'btn btn-primary' : 'btn btn-outline-primary' }>
					<Trans>Beirniadu</Trans>
				</Link>
			}
			{ cyfeilydd &&
				<Link to="/cyfeilio" className={ location.pathname.startsWith('/cyfeilio') ? 'btn btn-primary' : 'btn btn-outline-primary' }>
					<Trans>Cyfeilio</Trans>
				</Link>
			}
			{ /* (beirniad || cyfeilydd) &&
				<Link to="/proffil" className={ location.pathname.startsWith('/proffil') ? 'btn btn-primary' : 'btn btn-outline-primary' }>
					<Trans>Proffil</Trans>
				</Link>
			*/ }
			</div>
		</div>
	);

}

export function Troedyn() {
	const auth = useAuth();
	const {status, data: user} = useUser();

	function allgofnodi(e) {
		e.preventDefault();
		auth.signOut().then(() => {
			redirect("/");
		});
	}

	return (
		<div className="container container-max-md d-print-none fs-7">
			<p><BotwmCymorth className="ps-0"/></p>
			<p><Trans>Os oes gennych chi unrhyw gwestiynau am y cystadlaethau, cysylltwch â <a href="mailto:cystadlu@eisteddfod.cymru">cystadlu@eisteddfod.cymru</a>.</Trans></p>
			<p><Trans>Os ydych chi’n profi anawsterau technegol wrth ddefnyddio’r wefan hon, cysylltwch â <a href="mailto:cymorth@artistiaid-eisteddfod.cymru">cymorth@artistiaid-eisteddfod.cymru</a>.</Trans></p>
			{ status !== 'loading' && user &&
				<p><Trans>Mewngofnodwyd fel {user.email}. <button className="btn btn-link p-0 m-0 d-inline align-baseline fs-7" onClick={allgofnodi}>Gadael</button>.</Trans></p>
			}
		</div>
	);
}

export function BotwmCymorth({className}) {
	const location = useLocation();
	className = (location.pathname.startsWith('/cymorth')
		? 'btn btn-sm'
		: 'btn btn-sm btn-link')
	+ (className ? ' ' + className : '');
	return (
		<Link to="/cymorth" className={className}>
			<QuestionCircle/>
			<span><Trans>Cymorth</Trans></span>
		</Link>
	);
}

function BotwmMewngofnodi() {
	const auth = useAuth();
	const {status, data: user} = useUser();

	function allgofnodi(e) {
		e.preventDefault();
		auth.signOut().then(() => {
			redirect("/");
		});
	}

	if (status === 'loading') return;

	if (user) return (
		<button type="button" onClick={allgofnodi} className="btn btn-sm btn-outline-secondary">
			<BoxArrowRight/>
			<span><Trans>Gadael</Trans></span>
		</button>
	);

	return (
		<a href="/cofrestru" className="btn btn-sm btn-outline-secondary">
			<BoxArrowInRight/>
			<span><Trans>Mewngofnodi</Trans></span>
		</a>
	);

	// return (
	// 	<Link to="/" className="btn btn-sm btn-outline-secondary">
	// 		<BoxArrowInRight/>
	// 		<span><Trans>Mewngofnodi</Trans></span>
	// 	</Link>
	// );

}
