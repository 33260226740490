import { useRef, useEffect } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { Outlet, useLocation } from "react-router-dom";
import { doc } from 'firebase/firestore';

//import Neges from '../darnau/Neges';
import { Pennyn, Troedyn } from '../darnau/Pennyn';

export function Root() {

	const { status: apStatus, data: ap } = useFirestoreDocData(doc(useFirestore(), 'cyffredinol', 'ap'));
	const fersiwn = useRef(null);
	const location = useLocation();

	useEffect(() => {
		if (apStatus === 'success' && ap) fersiwn.current = ap.fersiwnBlaen;
	}, [apStatus]);

	useEffect(() => {
		if (ap?.fersiwnBlaen && fersiwn.current !== ap.fersiwnBlaen) {
			window.location.reload();
		}
	}, [location]);

	return (
		<>
			<Pennyn />
			<div className="container container-max-md mb-5">
				{/* <Neges/> */}
				<Outlet />
			</div>
			<Troedyn />
		</>
	);
}
