// import { Link } from 'react-router-dom';
// import { useBlwyddyn } from '../lib/ApBlaen';

export default function Rhif({rhif, cystadleuaeth}) {
	const dosbarth = 'statws-' + (cystadleuaeth?.statws || 'dangos');
	return (
		<span className={ 'badge rounded-pill ' + dosbarth }>
			{rhif}
		</span>
	);
/*
	const blwyddyn = useBlwyddyn();
	const dosbarth = 'statws-' + (cystadleuaeth?.statws || 'dangos');
	rhif ??= cystadleuaeth?.rhif;
	if (cystadleuaeth?.id) {
		return (
			<Link to={ `/${blwyddyn}/cystadlaethau/${cystadleuaeth.id}/golygu` } className={ 'badge rounded-pill ' + dosbarth }>
				{cystadleuaeth.rhif}
			</Link>
		);
	}
	return (
		<Link to={ `/${blwyddyn}/cystadlaethau/${rhif}` } className={ 'badge rounded-pill ' + dosbarth }>
			{rhif}
		</Link>
	);
*/
}
