import {
	FileEarmark, FileEarmarkText, FileEarmarkWord, FileEarmarkPdf, FileEarmarkPlay,
	FileEarmarkSlides, FileEarmarkImage, FileEarmarkMusic, FileEarmarkSpreadsheet,
	FileEarmarkZip, FileEarmarkRichtext
 } from 'react-bootstrap-icons';

export default function EiconFfeil({ffeil}) {
	const ext = ffeil.split('.').pop().toLowerCase();
	switch(ext) {
		case 'doc':
		case 'docx':
			return <FileEarmarkWord/>;
		case 'pdf':
			return <FileEarmarkPdf/>;
		case 'odt':
		case 'rtf':
		case 'rtfd':
		case 'pages':
			return <FileEarmarkRichtext/>;
		case 'txt':
			return <FileEarmarkText/>;
		case 'mov':
		case 'mp4':
		case 'mpg':
		case 'mpeg':
		case 'm4v':
			return <FileEarmarkPlay/>;
		case 'wav':
		case 'mp3':
		case 'm4a':
			return <FileEarmarkMusic/>;
		case 'ppt':
		case 'pptx':
		case 'key':
			return <FileEarmarkSlides/>;
		case 'jpg':
		case 'jpeg':
		case 'png':
		case 'gif':
		case 'webp':
		case 'heic':
		case 'heif':
			return <FileEarmarkImage/>;
		case 'xls':
		case 'xlsx':
		case 'numbers':
			return <FileEarmarkSpreadsheet/>;
		default:
			return <FileEarmark/>
	}
}
