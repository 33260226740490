import { useState, useEffect } from 'react';
import { useStorage } from 'reactfire';
import { ref, getDownloadURL, listAll } from "firebase/storage";

import ListGroup from 'react-bootstrap/ListGroup';
import EiconFfeil from '../darnau/EiconFfeil';

export default function RhestrFfeiliau({path, className, children}) {
	const [ffeiliau, setFfeiliau] = useState(null);
	const storage = useStorage();
	useEffect(() => {
		const ffolder = ref(storage, path);
		listAll(ffolder).then((res) => {
			const items = res.items.filter(item => {
				return !item.name.startsWith('.');
			});
			setFfeiliau(items);
		}).catch((error) => {
			// Wps
		});
	}, []);

	if (ffeiliau && ffeiliau.length) {
		return (
			<>
				{children}
				<ListGroup className={className}>{
					ffeiliau.map(eitem => <RhestrFfeiliauEitem key={eitem.fullPath} eitem={eitem}/>)
				}</ListGroup>
			</>
		);
	}
}

function RhestrFfeiliauEitem({eitem}) {
	const [url, setUrl] = useState(null);

	function handleClick(e) {
		if (!url) {
			e.preventDefault();
			const ffenest = window.open('', e.target.target);
			getDownloadURL(eitem).then(url => {
				setUrl(url);
				ffenest.location = url;
			});
		}

		// if (!url) {
		// 	e.preventDefault();
		// 	getDownloadURL(eitem).then(url => {
		// 		setUrl(url);
		// 		window.open(url, '_blank');
		// 	});
		// }
	}
//		<a href={url} onClick={handleClick} target="_blank" rel="noreferrer">

	return (
		<ListGroup.Item as="a" action href={url} onClick={handleClick} target="_blank" rel="noreferrer">
			<EiconFfeil ffeil={eitem.name}/>
			<span>{ eitem.name }</span>
		</ListGroup.Item>
	);
}