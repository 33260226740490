import { Trans, t } from '@lingui/macro'
import MaesTestun from '../darnau/MaesTestun';
import Bloc from './Bloc';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { glanhauTestun } from '../cyffredinol';
import { forwardRef, useRef, useImperativeHandle, useCallback } from 'react';

const Gwybodaeth = forwardRef(function Gwybodaeth({cais, blociau, setBlociau, nesaf}, ref) {

	const bloc = 'gwybodaeth';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const formRef = useRef(null);

	function arAnfon(e) {
		e.preventDefault();
		if (!e.target.checkValidity()) {
			e.target.reportValidity();
		} else if (cadwFfurflen(e.target)) {
			nesaf();
		}
	}

	const cadwFfurflen = useCallback((form) => {
		const formData = new FormData(form);
		const data = {};
		for (const key of ['cyswllt', 'ffon', 'enw', 'enw_iawn', 'bro', 'cynnwys']) {
			// TWDW ANGEN RHOI NÔL I GOFRESTRU
			if (formData.has(key)) {
				const val = glanhauTestun(formData.get(key));
				if (val !== cais[val]) {
					data[key] = val;
				}
			}
		}
		const cwblhawyd = form.checkValidity();
		if (cwblhawyd !== !!blociau[bloc]) {
			data[`gofynion.${bloc}`] = cwblhawyd;
			blociau[bloc] = cwblhawyd;
			setBlociau({...blociau});
		}
		if (Object.keys(data).length) {
			updateDoc(cais.ref, data);
		}
		return true;
	}, [cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	const golygu = true;//!('cyfansoddiadau' in blociau);

	return (
	<Bloc label={t`Manylion cystadleuydd`} formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>
		{ 'cyfansoddiadau' in blociau
			?
			<>
			<MaesTestun name="enw" required defaultValue={ cais.enw } label={ t`Ffugenw` } disabled={!golygu}>
				<Trans>Dewiswch ffugenw ar gyfer eich cais.</Trans>
			</MaesTestun>
			<MaesTestun name="enw_iawn" required defaultValue={ cais.enw_iawn } label={ t`Enw llawn` } disabled={!golygu}>
				<Trans>Enw llawn y cystadleuydd sy’n cyflwyno’r gwaith.</Trans>
			</MaesTestun>
			<MaesTestun gair="200" rows="10" name="cynnwys" defaultValue={cais.cynnwys} type="textarea"
				label={ t`Bywgraffiad` }>
				<Trans>Ychydig o wybodaeth amdanoch chi eich hun.</Trans>
			</MaesTestun>
			</>
			:
			<>
			<MaesTestun name="enw" required defaultValue={ cais.enw } label={ t`Enw’r sawl sy’n cystadlu` }>
				<Trans>Rhowch enw’r côr, parti, band, prosiect neu unigolion sy’n cystadlu.</Trans>
			</MaesTestun>
			<MaesTestun name="cyswllt" defaultValue={ cais.cyswllt } label={ t`Enw cyswllt` }>
				<Trans>Enw person cyswllt, os yn wahanol i enw’r cystadleuydd/cystadleuwyr.</Trans>
			</MaesTestun>
			</>
		}
		<MaesTestun name="bro" required defaultValue={ cais.bro } label={ t`Bro` }>
			<Trans>Enw eich dinas, tref, pentref neu ardal. Byddwn yn defnyddio’r wybodaeth wrth gyhoeddi enillwyr.</Trans>
		</MaesTestun>
		<MaesTestun name="ffon" required defaultValue={ cais.ffon } label={ t`Rhif ffôn` }>
			<Trans>Rhif ffôn cyswllt.</Trans>
		</MaesTestun>
		<Botwm/>
	</Bloc>
	);
});

export default Gwybodaeth;