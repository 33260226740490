import { collection, collectionGroup, doc, getDoc, getDocs, setDoc, addDoc, updateDoc,
	query, where, limit, deleteField, Timestamp, DocumentReference, serverTimestamp
} from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { toDate, formatInTimeZone } from 'date-fns-tz';
import { useLingui } from "@lingui/react"
import { unflatten } from 'flat';
import { cy, enGB } from 'date-fns/locale'

//const dateLocales = { cy, enGB };

export const blwyddyn = 2025;

export function enwId(data, id) {
	for (const rhes of data) {
		if (rhes.id === id) return rhes.enw;
	}
}

export function eitemRhestr(rhestr, id) {
	for (const rhes of rhestr) {
		if (rhes.id === id) return rhes;
	}
}

export function gwraiddCasgliad(enwCasgliad) {
	switch(enwCasgliad.split('/')[0]) {
		case 'pobl':
			return enwCasgliad;
		case 'ceisiadau':
		case 'prisiau':
		case 'perfformiadau':
		case 'adnoddau':
		case 'cerbydau':
			return `${blwyddyn}/preifat/${enwCasgliad}`;
		default:
			return `${blwyddyn}/cynhyrchu/${enwCasgliad}`;
	}
}

export function caelCasgliad(firestore, enwCasgliad, aml = false) {
	if (aml) return collectionGroup(firestore, gwraiddCasgliad(enwCasgliad));
	return collection(firestore, gwraiddCasgliad(enwCasgliad));
}

export async function caelDogfen(firestore, enwCasgliad, dogfenId, dyfnder = 0) {
	const docRef = await doc(firestore, gwraiddCasgliad(enwCasgliad), dogfenId);
	const docSnap = await getDoc(docRef);
	return await caelDataDogfen(docSnap, dyfnder);
}

export async function canfodDogfen(firestore, enwCasgliad, amod, dyfnder = 0) {
	const q = query(caelCasgliad(firestore, enwCasgliad), where(...amod), limit(1));
	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) return null;
	return caelDataDogfen(querySnapshot.docs[0], dyfnder);
}

async function caelDataDogfen(docSnap, dyfnder = 0) {
	// const docSnap = await getDoc(docRef);
	if (docSnap.exists()) {
		const data = docSnap.data();
		if (dyfnder) {
			for (const key of Object.keys(data)) {
				if (data[key] instanceof DocumentReference) {
					const docSnap = await getDoc(data[key]);
					data[key] = await caelDataDogfen(docSnap, dyfnder - 1);
				}
			}
		}
		data.id = docSnap.id;
		data.ref = docSnap.ref;
		return data;
	}
	return null;
}

export async function creuCofnod(colOrDocRef, data) {
	for (const key of Object.keys(data)) {
		if (gwag(data[key])) {
			delete data[key];
			continue;
		}
		if (key.includes('.')) {
			let obj = data;
			const parts = key.split('.');
			const lastPart = parts.pop();
			for (const part of parts) {
				if (!(part in obj)) {
					obj[part] = {};
				}
				obj = obj[part];
			}
			obj[lastPart] = data[key];
			delete data[key];
		}
	}
	if (!data.meta) data.meta = {};
	data.meta.crewyd = maesMeta();
	data.meta.newidiwyd = maesMeta();

	if (colOrDocRef.type === 'document') {
		return await setDoc(colOrDocRef, data);
	}
	return await addDoc(colOrDocRef, data);
}

export async function diweddaruCofnod(docRef, data) {
	for (const key of Object.keys(data)) {
		if (gwag(data[key])) data[key] = deleteField();
	}
	data['meta.newidiwyd'] = maesMeta();
	await updateDoc(docRef, data);
	return docRef;
}

export function maesMeta() {
	const meta = {
		pryd: serverTimestamp(),
		dull: 'cofrestru',
	};
	const cyfrif = getAuth()?.currentUser?.uid;
	if (cyfrif) {
		meta.cyfrif = cyfrif;
	}
	return meta;
}

export function gwag(val) {
	return val === undefined || val === null || val === ''; // caniatau 0 a false
}

export function Dyddiad({dyddiad, fformat}) {
	const {i18n} = useLingui();
	const locale = i18n.locale === 'cy' ? cy : enGB;
	return formatInTimeZone(dyddiad, 'Europe/London', fformat, {locale}).replace('dydd ', '');
}

export function dangosDyddiad(dyddiad, fformat) {
	return formatInTimeZone(dyddiad, 'Europe/London', fformat);
}

// string -> Firestore
export function dyddiadFS(dyddiad) {
	return Timestamp.fromDate( toDate(dyddiad, {timeZone: 'Europe/London'}) );
}

export function glanhauTestun(testun) {
	return testun.replace(/<[^>]*>?/gm, '').replace(/\s*?\n\s*?(\n)?\s*/g, "\n$1").trim();
}

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function formDataObject(formData, filterKeys, deep) {
	let data = Object.fromEntries(formData.entries());
	for (const key of Object.keys(data)) {
		if (key.endsWith('[]')) {
			data[key.slice(0, -2)] = formData.getAll(key);
			delete data[key];
		}
	}
	if (deep) data = unflatten(data);
	if (filterKeys) {
		for (const deleteKey of Object.keys(data).filter(key => !filterKeys.includes(key))) {
			delete data[deleteKey];
		}
	}
	return data;
}

const hydEnwFfeil = 28; // cyn yr estyniad

export function enwFfeilUnigryw(enw, enwau) {
	enw = enwFfeilGlan(enw);
	if (!enwau) return enw;
	while (enwau.includes(enw)) {
		enw = enw.replace(/(?: +(\d+))?(\.\w+)?$/, (m, m1, m2) => {
			return ' ' + (m1 ? +m1 + 1 : 2) + m2;
		}, enw);
	}
	return enw;
}

export function enwFfeilGlan(ffeil) {
	const m = ffeil.trim().match(/^(.*?)(\.\w+)?$/);
	const enw = m[1].replace(/[\s_]+/g, ' ').replace(/[^ \p{L}\d-]+/gu, '').substring(0, hydEnwFfeil).replace(/[ -]+$/, '');
	const ext = m[2]?.toLowerCase() || '';
	return enw + ext;
}

// https://dns.google/resolve?name=slebog.net&type=MX
const parthauGwiriwyd = {};
export async function gwirioParthEbost(ebost) {
	const parth = ebost.trimEnd().split('@')[1];
	if (!parth || !parth.includes('.')) return false;
	if (!(parth in parthauGwiriwyd)) {
		let res;
		try {
			res = await timeout(3000, fetch(`https://dns.google/resolve?name=${parth}&type=MX`));
		} catch (e) {
			console.error(e);
		}
		if (res?.ok) {
			const data = await res.json();
			if (data.Status === 0) {
				parthauGwiriwyd[parth] = true;
			} else if (data.Status === 3) {
				parthauGwiriwyd[parth] = false;
			}
		}
	}
	return parthauGwiriwyd[parth] ?? null;
}

function timeout(ms, promise) {
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error('TIMEOUT'))
		}, ms)
		promise.then(value => {
			clearTimeout(timer)
			resolve(value)
		})
		.catch(reason => {
			clearTimeout(timer)
			reject(reason)
		})
	});
}
