import { caelCasgliad } from '../cyffredinol';
import { useUser, useFirestore, useFirestoreDocDataOnce, useFirestoreCollectionData } from 'reactfire';
import { query, where, orderBy } from 'firebase/firestore';
import { LinkContainer } from 'react-router-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons';
import { useRolau } from '../lib/ApBlaen';
import { Navigate} from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Prysur from '../darnau/Prysur';

// import { AppContext } from '../lib/ApBlaen';
import { Trans, t } from '@lingui/macro'

export function Cofrestru() {
    const {status, data: user} = useUser();
	const rolau = useRolau();

	if (status === 'loading' || !rolau) return <Prysur/>;

	if (!rolau.includes('cystadleuydd')) {
		if (rolau.includes('artist')) return <Navigate to="/perfformio" replace={true}/>;
		if (rolau.includes('beirniad')) return <Navigate to="/beirniadu" replace={true}/>;
		if (rolau.includes('cyfeilydd')) return <Navigate to="/cyfeilio" replace={true}/>;
		if (rolau.includes('trefnydd')) return <Navigate to="/digwyddiadau" replace={true}/>;
	}

	return (
		<CerdiauCais user={user}/>
	);
}

function CerdiauCais({ user }) {
	const casgliad = caelCasgliad(useFirestore(), 'ceisiadau');

	const ymholiad = query(casgliad, where('meta.cyfrif', '==', user.uid), orderBy('rhif'));
	const {status, data} = useFirestoreCollectionData(ymholiad, { idField: 'id' });

	if (status === 'loading') {
		return <Prysur/>;
	}

	return (
		<>
			<h1 className="mb-4"><Trans>Ceisiadau cystadlu</Trans></h1>
			<div>
			{data.length
				? data.map((eitem) => {
					if (!eitem.cystadleuaeth) return '';
					return <CerdynCais cais={eitem} key={eitem.id} />
				})
				: <p><Trans>Dim ceisiadau cystadlu.</Trans></p>
			}
			</div>
		</>
	);

}

function CerdynCais({ cais }) {
	// const { i18n } = useLingui();

	const {status, data: cyst} = useFirestoreDocDataOnce(cais.cystadleuaeth);
	if (status === 'loading') return <Prysur/>;

	const cyflawn = caisYnGyflawn(cais, cyst);
	return (
		<Card className="mb-4">
			<Card.Body>
				<Card.Title>{ cyst.rhif } • { cyst.enw }</Card.Title>
				<Card.Subtitle className="mb-2 text-body-secondary">{ cais.enw || t`Heb ddewis enw` }</Card.Subtitle>
				<Card.Text>{ cyflawn
					? <Trans>Mae’ch cais yn gyflawn ar hyn o bryd. Byddwn yn anfon neges atoch chi os bydd angen unrhyw wybodaeth bellach.</Trans>
					: <Trans>Nid yw’ch cais yn gyflawn ar hyn o bryd. Ewch ati i lenwi’r wybodaeth sydd ei hangen.</Trans>
				}</Card.Text>
				<div className="d-flex">
					<LinkContainer to={ '/cais/' + cais.id }>
						<Button variant="primary" className="ms-auto">
							<span><Trans>Dangos</Trans></span>
							<ArrowRight/>
						</Button>
					</LinkContainer>
				</div>
			</Card.Body>
		</Card>
	);
}

export function Enw({cais}) {
	if (cais.enw) return <span className="enw">{cais.enw}</span>
	return <i className="enw enw-gwag">{t`Heb ddewis enw`}</i>
}

export function caisYnGyflawn(cais, cyst) {
	if (!cais?.gofynion?.gwybodaeth) return false;
	if (!cyst._gofynion) return true;
	for (const gofyniad in cyst._gofynion) {
		// if (gofyniad === 'technegol') continue;
		if (['cyweirnod', 'offeryn'].includes(gofyniad)) continue; // TWDW
		if (!cais.gofynion[gofyniad]) return false;
	}
	return true;
}

