import { Trans, t } from '@lingui/macro'
import Prysur from '../darnau/Prysur';
import Bloc from './Bloc';
import MaesFfeiliau from '../darnau/MaesFfeiliau';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useUser } from 'reactfire';
import { useBlwyddyn } from '../lib/ApBlaen';

const Cyfansoddiadau = forwardRef(function Cyfansoddiadau({cais, blociau, setBlociau, nesaf}, ref) {

	const blwyddyn = useBlwyddyn();
	const bloc = 'cyfansoddiadau';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const {status: userStatus, data: user} = useUser();
	const [nifer, setNifer] = useState(0);

	const golygu = true; // agor neu beidio

	function arAnfon(e) {
		e.preventDefault();
		cadwFfurflen(e.target);
		nesaf();
	}

	const cadwFfurflen = useCallback(() => {
		if (nifer !== blociau[bloc]) {
			const data = {};
			data[`gofynion.${bloc}`] = nifer;
			updateDoc(cais.ref, data);
			blociau[bloc] = nifer;
			setBlociau({...blociau});
		}
		return true;
	}, [nifer, cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen();
			},
		};
	}, [cadwFfurflen]);

	function arNewidFfeiliau(ffeiliau) {
		setNifer(ffeiliau?.length || 0);
		cadwFfurflen();
	}

	function arLwythoFfeiliau(ffeiliau) {
		setNifer(ffeiliau?.length || 0);
	}

	if (userStatus === 'loading') return <Prysur/>;
	const llwybr = `${blwyddyn}/cystadleuwyr/${user.uid}/${cais.id}/cyfansoddiadau`;
	const accept = ".docx,.rtf,.txt,.pdf,.odt,text/*,application/pdf,application/rtf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text,sain,fideo";

	return (
		<Bloc label={ t`Cyfansoddiadau` } eventKey={bloc} statws={statws} onSubmit={arAnfon}>
			<MaesFfeiliau label={t`Eich ffeiliau`} path={llwybr} accept={accept}
				arLwytho={arLwythoFfeiliau} arNewid={arNewidFfeiliau} golygu={golygu}>
			</MaesFfeiliau>
			<p className="mt-2"><small className="text-muted form-text">
				<Trans>Gallwch lwytho dogfennau .docx, .rtf, .pdf neu .txt; ffeiliau sain .wav, .mp3 neu .m4a; neu ffeiliau fideo .mp4, .mov neu .m4v. Nodwch nad ydym yn derbyn ffeiliau .doc – troswch y ffeiliau hyn i fformat .rtf cyn eu llwytho.</Trans>
			</small></p>
			<Botwm/>
		</Bloc>
	);

});

export default Cyfansoddiadau;