import { Editor } from '@tinymce/tinymce-react';
import { useRef, useId } from 'react';

const GWIRYDD = false;

function tinyInit(lang = 'cy') {
	const init = {
		menubar: false,
		plugins: 'link lists',// wordcount',
		toolbar: 'bold italic | numlist bullist indent outdent | blockquote link',
		statusbar: false,
		valid_elements: 'p,b/strong,i/em,a[href],ul,ol,li,blockquote,hr,br',
		content_style: 'body{font:16px/1.5 system-ui}',
		height: 300,
		entity_encoding: 'raw',
	};
	if (GWIRYDD && lang === 'cy') {
		init.plugins += ' gwirydd';
		init.external_plugins = {
			gwirydd: '../gwirydd/plugin.js',
		};
		init.gwirydd = {
			allwedd: '3e4d3bc0-8a07-44e0-b387-3b5b87e66af2',
		};
		init.toolbar += ' | gwirydd acenion';
	}
	return init;
}

export default function MaesHtml({ label, sublabel, name, defaultValue, onInit, setStad, lang, disabled }) {

	const editorRef = useRef(null);
	const id = useId();

	return (
		<div className="mb-3">
			{ label && <label className="form-label" htmlFor={id}>{label}
				{ sublabel && <span className="text-secondary fw-normal"> {sublabel}</span>}
			</label> }
			<Editor
				id={id}
				textareaName={name}
				initialValue={defaultValue}
				init={tinyInit(lang)}
				onInit={(e, editor) => editorRef.current = editor}
				onDirty={() => setStad('budur')}
				lang={ lang || 'cy' }
				disabled={disabled}
			  />
		</div>
	);
}
