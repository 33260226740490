import { CheckCircleFill } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';

function Bloc({ disabled, formRef, eventKey, label, children, statws, onSubmit, onChange }) {
	const cwblhawyd = statws === 'cwblhawyd' ? true : false;
	return (
	<Accordion.Item eventKey={eventKey} className={statws}>
		<Accordion.Header>{label + ' '}{cwblhawyd && <CheckCircleFill color="var(--bs-success)" className="ms-1"/>}</Accordion.Header>
		<Accordion.Body>
			<Form disabled={disabled} ref={formRef} name={eventKey} onSubmit={onSubmit} onChange={onChange}>
				{ children }
			</Form>
		</Accordion.Body>
	</Accordion.Item>
	);
}

export default Bloc;