import { useLingui } from "@lingui/react"
import { dynamicActivate } from '../lib/ApBlaen';

function NewidIaith({ className }) {
    const {i18n} = useLingui();

	function arGlic(e) {
		e.preventDefault();
		dynamicActivate(e.target.value);
	}

	return (
	<div className={ 'btn-group btn-group-sm' + (className ? ' ' + className : '') } role="group">
		<button value="cy" type="button" onClick={arGlic} className={ i18n.locale === 'en' ? 'btn btn-outline-primary' : 'btn btn-primary' }>Cymraeg</button>
		<button value="en" type="button" onClick={arGlic} className={ i18n.locale === 'en' ? 'btn btn-primary' : 'btn btn-outline-primary' }>English</button>
	</div>
	);
}

export default NewidIaith;