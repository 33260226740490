import { query, where, orderBy, getDocs, getDoc } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { caelDogfen, caelCasgliad } from '../cyffredinol';

//import { Ffurflen, MaesEnw, MaesTestun, MaesHtml, MaesTudalen } from '../display/ffurflen';
import { useFirestore, useUser } from 'reactfire';
import { ArrowLeft, ArrowRight, Award } from 'react-bootstrap-icons';
import Tab from 'react-bootstrap/Tab';

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Prysur from '../darnau/Prysur';
import CynnwysCofnod from '../darnau/CynnwysCofnod';
import RhestrFfeiliau from '../darnau/RhestrFfeiliau';
import AmserlenCystadlu from '../darnau/AmserlenCystadlu';
// import Tudalen from '../darnau/Tudalen';

import DullTocynnau from '../darnau/DullTocynnau';
import ManylionBanc from '../darnau/ManylionBanc';
import Tabiau from '../darnau/Tabiau';

import Gwybodaeth from '../blociau/Gwybodaeth';
//import Banc from '../blociau/Banc';
import Cyfansoddiadau from '../blociau/Cyfansoddiadau';
import Dewisiad from '../blociau/Dewisiad';
import Darnau from '../blociau/Darnau';
import Technegol from '../blociau/Technegol';
import Tocynnau from '../blociau/Tocynnau';
import Mynediad from '../blociau/Mynediad';
import Celf from '../blociau/Celf';
import PlacCelf from '../blociau/PlacCelf';

import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useBlwyddyn } from '../lib/ApBlaen';

export function Cais() {
	const {i18n} = useLingui();
	const blwyddyn = useBlwyddyn();

	const firestore = useFirestore();
	const caisId = useLoaderData();
	const casgliad = 'ceisiadau';
	const refs = useRef({});
    const {status: userStatus, data: user} = useUser();

	const [dangos, setDangos] = useState(null);
	const [blociau, setBlociau] = useState([]);
	const [cyflawn, setCyflawn] = useState(null);
	const [cais, setCais] = useState(null);
	const [cyst, setCyst] = useState(null);
	const [slot, setSlot] = useState(null);
	const [lle, setLle] = useState(null);

	useEffect(() => {
		async function getData() {

			const cais = await caelDogfen(firestore, casgliad, caisId); //, true);
			if (!cais || !cais.cystadleuaeth) return null;

			const cystSnap = await getDoc(cais.cystadleuaeth); // oherwydd eisoes yn ref
			const cyst = cystSnap.exists() ? cystSnap.data() : null;
			cyst.id = cystSnap.id;
console.log(cyst);
			if (!cyst._gofynion?.cyfansoddiadau) {
				const ymholiad = query(
					caelCasgliad(firestore, 'amserlen'),
					where('rhifau', 'array-contains', cyst.rhif),
					orderBy('dechrau')
				);
				const querySnapshot = await getDocs(ymholiad);
				for (const doc of querySnapshot.docs) {
					const data = doc.data();
					if (data.mathau?.includes('cystadlu') || data.mathau?.includes('perfformiad')) {
						data.id = doc.id;
						setSlot(data);
						if (data.llwyfan) {
							setLle(await caelDogfen(firestore, 'llefydd', data.llwyfan));
						}
						break;
					}
				}
			}

			const cwblhawyd = (cais && cais.gofynion) ? cais.gofynion : {};

			const blociauPosib = [
				'cyfansoddiadau',
				'dewisiad',
				'cyweirnod',
				'offeryn',
				'darnau',
				'technegol',
				'tocynnau',
				'mynediad',
				'celf',
				'plac',
			];
			const blociau = {
				gwybodaeth: !!cwblhawyd.gwybodaeth,
				//banc: !!cwblhawyd.banc,
			};

			if (cyst._gofynion) {
				for (const bloc of blociauPosib) {
					if (cyst._gofynion[bloc]) {
						blociau[bloc] = !!cwblhawyd[bloc];
					}
				}
				if (!cyst._gofynion.cyfansoddiadau) {
					blociau.tocynnau = !!cwblhawyd.tocynnau;
					blociau.technegol = !!cwblhawyd.technegol;
				}
			}

			// offer trwm
			if ([101,102,103,104,161,262,263,264,265,408,901,905].includes(+cyst.rhif)) {
				blociau.mynediad = !!cwblhawyd.mynediad;
			}
			// celf
			else if ([551, 552, 553, 556].includes(+cyst.rhif)) {
				blociau.celf = !!cwblhawyd.celf;
				delete blociau.cyfansoddiadau;
				delete blociau.tocynnau;
			}
			// plac celf?
			else if (cyst.rhif === 555) {
				blociau.plac = !!cwblhawyd.plac;
				delete blociau.cyfansoddiadau;
				delete blociau.tocynnau;
			}

			setBlociau(blociau);
			for (const bloc in blociau) {
				if (!blociau[bloc]) {
					setDangos(bloc);
					break;
				}
			}
			setCyst(cyst);
			setCais(cais);
		}
		getData();
	}, [caisId, firestore]);

	useEffect(() => {
		for (const gwerth of Object.values(blociau)) {
			if (!gwerth) {
				setCyflawn(false);
				return;
			}
		}
		setCyflawn(true);
	}, [blociau]);

	if (!cais) return <Prysur/>;

	if (!cyst) return t`Mae problem gyda’r cais. Cysylltwch â ni am gymorth.`;

	function arNewidBloc(activeKey, e) {
		const bloc = refs.current[dangos];
		if (!bloc || !bloc.cadw || bloc.cadw()) {
			setDangos(activeKey);
		}
	}

	function dangosNesaf() {
		const keys = Object.keys(blociau);
		const nesaf = keys[keys.indexOf(dangos) + 1] || null;
		setDangos(nesaf);
	}

	if (userStatus === 'loading') return <Prysur/>;
	const llwybr = `${blwyddyn}/cystadleuwyr/${user.uid}/${cais.id}`;

	const terfynol = ['terfynol', 'dimcystadlu'].includes(cyst.statws);

	return (
		<>
		<h1 className="title mb-5">{cyst.rhif} • {(i18n.locale === 'en' && cyst.enw_en) ? cyst.enw_en : cyst.enw}</h1>

		{ !terfynol && <DullTocynnau dogfen={cais}/> }

		<Tabiau className="mb-3" defaultActiveKey={terfynol ? 'canlyniad' : 'cais'}>

			{ terfynol &&
				<Tab eventKey="canlyniad" title={t`Canlyniad`}>
					<CanlyniadCais cais={cais} cyst={cyst}/>
				</Tab>
			}

			<Tab eventKey="cais" title={t`Eich cais`}>

		<Accordion defaultActiveKey={dangos} activeKey={dangos} onSelect={arNewidBloc} className="mb-3">
			<Gwybodaeth ref={(ref) => refs.current.gwybodaeth = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			{/* <Banc ref={(ref) => refs.current.banc = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} /> */}
			{ 'cyfansoddiadau' in blociau &&
				<Cyfansoddiadau ref={(ref) => refs.current.cyfansoddiadau = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ 'celf' in blociau &&
				<Celf ref={(ref) => refs.current.celf = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ 'plac' in blociau &&
				<PlacCelf ref={(ref) => refs.current.plac = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ ('dewisiad' in blociau || 'cyweirnod' in blociau || 'offeryn' in blociau) &&
				<Dewisiad ref={(ref) => refs.current.dewisiad = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ 'darnau' in blociau &&
				<Darnau llwybr={llwybr} ref={(ref) => refs.current.darnau = ref} cais={cais} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ 'technegol' in blociau &&
				<Technegol llwybr={llwybr} ref={(ref) => refs.current.technegol = ref} cais={cais} slot={slot} lle={lle} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ 'tocynnau' in blociau &&
				<Tocynnau ref={(ref) => refs.current.technegol = ref} cais={cais} artist={cais} cyst={cyst} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
			{ 'mynediad' in blociau &&
				<Mynediad ref={(ref) => refs.current.mynediad = ref} cais={cais} cyst={cyst} blociau={blociau} setBlociau={setBlociau} nesaf={dangosNesaf} />
			}
		</Accordion>

		{ !dangos && ( cyflawn
			? <p><Trans>Diolch. Mae’ch cais yn gyflawn ar hyn o bryd. Byddwn yn anfon neges atoch chi os bydd angen unrhyw wybodaeth bellach.</Trans></p>
			: <p><Trans>Nid yw’ch cais yn gyflawn eto. Cyflwynwch y wybodaeth sydd ei hangen cyn gynted â phosib.</Trans></p>
		) }

			</Tab>

			<Tab eventKey="gwybodaeth" title={t`Gwybodaeth`}>
				{ lle?._cyswllt &&
					<>
					<h4 className="fs-4 mb-4"><Trans>Manylion cyswllt</Trans></h4>
					<div className="content blwch newlines mb-3"><p>{lle._cyswllt}</p></div>
					</>
				}
				<RhestrFfeiliau className="mb-3" path={`${blwyddyn}/cystadlaethau/${cyst.id}/darnau`}/>
				<h4 className="fs-4 mb-4"><Trans>Disgrifiad</Trans></h4>
				<CynnwysCofnod eitem={cyst}/>
			</Tab>

			<Tab eventKey="amserlen" title={t`Amserlen`}>
				<AmserlenCystadlu cystadleuaeth={cyst}/>
			</Tab>

			{ /* <Tab eventKey="cyrraedd" title={t`Cyrraedd`}>
				<Tudalen enw="artistiaid"/>
			</Tab> */}

			{ cais.safle &&
				<Tab eventKey="banc" title={t`Manylion banc`}>
					<ManylionBanc dogfen={cais} cystadleuaeth={cyst}/>
				</Tab>
			}

		</Tabiau>

		<div className="my-5">
			<Link to="/" className="btn btn-secondary"><ArrowLeft/> <span><Trans>Eich ceisiadau</Trans></span></Link>
		</div>

		</>
	);
}

export function Botwm() {
	return (
		<div className="d-flex">
			<Button variant="primary" type="submit" className="ms-auto"><span><Trans>Nesaf</Trans></span> <ArrowRight/></Button>
		</div>
	);
}

export function Enw({cais}) {
	if (cais.enw) return <span className="enw">{cais.enw}</span>
	return <i className="enw enw-gwag">{t`Heb ddewis enw`}</i>
}

function CanlyniadCais({cais, cyst}) {

	// const tystysgrif = cais.tystysgrif;
	const tystysgrif = 'https://artistiaid-eisteddfod.cymru/pencefn/tystysgrif.php?c=' + cais.id;

	const safleoedd = [
		t`Buddugol`,
		t`Safle cyntaf`,
		t`Ail safle`,
		t`Trydydd safle`,
		t`Pedwerydd safle`,
		t`Pumed safle`
	];

	return (
		<>
		{cais.safle &&
			<>
			<h2 className="mb-4">{cyst.cyfansoddi ? <Trans>Buddugol</Trans> : safleoedd[cais.safle]}</h2>
			<p><Trans><b>Llongyfarchiadau</b>. Cofiwch <Link to={'../cais/' + cais.id + '#banc'}>lenwi eich manylion banc</Link> er mwyn derbyn unrhyw wobrau ariannol.</Trans></p>
			{tystysgrif &&
				<>
				<a href={tystysgrif} target="_blank" rel="noreferrer" className="btn btn-primary mb-3">
					<Award/>
					<span><Trans>Eich tystysgrif</Trans></span>
				</a>
				</>
			}
			</>
		}
		{cais.beirniadaeth &&
			<>
			<h4 className="mb-3"><Trans>Eich beirniadaeth</Trans></h4>
			<img className="img-fluid" src={cais.beirniadaeth} alt={<Trans>Eich beirniadaeth</Trans>}/>
			</>
		}
		{cais.beirniadaeth_html &&
			<>
			<h4 className="mb-3"><Trans>Sylwadau’r beirniaid</Trans></h4>
			<div className="blwch content" dangerouslySetInnerHTML={{__html: cais.beirniadaeth_html}}/>
			</>
		}
		</>
	);

}

