import { useAuth, useSigninCheck, useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { isSignInWithEmailLink, signInWithEmailLink  } from 'firebase/auth';
import { query, where, limit } from 'firebase/firestore';
import { blwyddyn, caelCasgliad } from '../cyffredinol';
import { useParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { t } from '@lingui/macro';
import Mewngofnodi from '../darnau/Mewngofnodi';
import Prysur from '../darnau/Prysur';

export const locales = {
	cy: "Cymraeg",
	en: "English",
};
export const defaultLocale = "cy";

dynamicActivate();

export async function dynamicActivate(locale) {
	if (!locale) {
		try {
			locale = localStorage.getItem('iaith');
		} catch (e) { }
	}
	locale = locales[locale] ? locale : defaultLocale;
	const {messages} = await import(`../locales/${locale}/messages`);
	i18n.load(locale, messages);
	i18n.activate(locale);
	try {
		localStorage.setItem('iaith', locale);
	} catch (e) { }
}

export const AppContext = createContext();

export function useRolau() {
	const context = useContext(AppContext);
	return context?.app?.rolau || null;
}

export function useBlwyddyn() {
	return blwyddyn; // gosod yn cyffredinol.jsx
}

export function useLlefydd() {
	return [];
}

export function usePersonCyfredol() {
	const auth = useAuth();
	const firestore = useFirestore();
	const uid = auth?.currentUser?.uid;
	const q = query(caelCasgliad(firestore, 'pobl'), where('meta.cyfrif', '==', uid), limit(1));
	const { status, data } = useFirestoreCollectionData(q, {idField: 'id'});
	if (status === 'loading') {
		return { status, data: null };
	}
	return { status, uid, data: typeof data === 'object' ? data[0] : null };
}

export function ApBlaen({children}) {
	const cyhoeddus = window.location.pathname.match(/^\/cofrestru\/(cerbyd|cymorth)/);

	const { status: signInCheckStatus, data: signInCheckResult } = useSigninCheck({validateCustomClaims: (claims) => {
		const rolau = ['artist','cystadleuydd', 'cyfeilydd', 'beirniad', 'trefnydd', 'gweinyddu', 'staff'];
		if (claims.rolau) {
			for (const rol of rolau) {
				if (claims.rolau.includes(rol)) {
					return { hasRequiredClaims: true }
				}
			}
		}
		return { hasRequiredClaims: false }
	}});
	// const { lang } = useParams();
	const auth = useAuth();

	const [gweithred, setGweithred] = useState('anfon');
	const [app, setAppState] = useState({});

	const setApp = useCallback((name, value) => {
		//app[name] = value;
		const newydd = {...app};
		newydd[name] = value;
		setAppState(newydd);
	}, [app, setAppState]);

	const neges = useCallback((testun, math = "info") => {
		setApp('neges', {testun, math});
	}, [setApp]);

	const mewngofnodiEbost = useCallback((ebost) => {
		//console.log('me', auth, ebost);
		signInWithEmailLink(auth, ebost, window.location.href)
		.then(async (result) => {
			const idTokenResult = await result.user.getIdTokenResult();
			app.rolau = idTokenResult.claims.rolau;
			app.user = result.user;
			setAppState({...app});
			//window.localStorage.removeItem('ebost');
			window.history.replaceState(null, '', window.location.pathname);
		})
		.catch((error) => {
			switch (error.code) {
				case "auth/invalid-action-code":
					neges(t`Mae’r ddolen yn hen neu wedi cael ei defnyddio eisoes. Rhowch eich cyfeiriad e-bost yn y blwch er mwyn derbyn dolen mewngofnodi newydd.`, "warning");
					setGweithred('anfon');
					break;
				case "auth/invalid-email":
					window.localStorage.removeItem('ebost');
					if (gweithred === 'mewngofnodi') {
						neges(t`Er mwyn i ni gadarnhau mai’r person iawn sy’n agor y ddolen hon, rhowch eich cyfeiriad e-bost yn y blwch os gwelwch yn dda.`);
					} else {
						neges(t`Dyw’r cyfeiriad e-bost ddim yn cyd-fynd â’n cofnodion. Gwnewch yn siwr eich bod yn defnyddio’r cyfeiriad cywir.`, "danger");
					}
					setGweithred('cadarnhau');
					break;
				default:
					console.error('Gwall', error.code, error);
					neges(t`Methwyd â mewngofnodi. Rhowch gynnig arall arni nes ymlaen, neu cysylltwch â ni am gymorth.`, "danger");
					setGweithred('anfon');
			}
		});
	}, [auth, gweithred]);

	useEffect(() => {
		if (signInCheckStatus === 'loading') return;
		async function getUser() {
			if (signInCheckResult.signedIn) {
//console.log('scr',signInCheckResult);
				if (signInCheckResult.hasRequiredClaims) {
					const idTokenResult = await signInCheckResult.user.getIdTokenResult();
					const rolau = idTokenResult.claims.rolau;
					app.rolau = rolau;
					app.user = signInCheckResult.user;
					setAppState({...app});
				} else {
					neges(t`Dyw’ch cyfrif ddim yn ddilys. Cysylltwch â ni am gymorth.`, "danger");
				}
			}
			else if (isSignInWithEmailLink(auth, window.location.href)) {
				let ebost = window.localStorage?.getItem('ebost');
				if (ebost) {
					setGweithred('mewngofnodi');
					mewngofnodiEbost(ebost);
				} else {
					setGweithred('cadarnhau');
					neges(t`Er mwyn i ni gadarnhau mai’r person iawn sy’n agor y ddolen hon, rhowch eich cyfeiriad e-bost yn y blwch os gwelwch yn dda.`);
				}
			}
			else {
				neges(t`Rhowch eich cyfeiriad e-bost yn y blwch er mwyn derbyn dolen mewngofnodi.`);
			}
		}
		getUser();
	}, [signInCheckStatus]);

	async function handleEmailSubmit(e) {
		e.preventDefault();
		const input = e.target.elements.ebost;
		if (!dilysuMaes(input)) return;
		const ebost = input.value;
		if (gweithred === 'cadarnhau') {
			mewngofnodiEbost(ebost);
		} else if (await anfonDolen(ebost)) {
			neges(t`Ry’n ni wedi anfon e-bost atoch chi gyda dolen i fewngofnodi i’r porth cofrestu. Cofiwch edrych yn eich ffolder sbam.`, "success");
		} else {
			neges(t`Methwyd anfon e-bost mewngofnodi. Gwiriwch y cyfeiriad, ceisiwch eto nes ymlaen, neu cysylltwch â ni am gymorth.`, "danger");
		}
	}

	function dilysuMaes(input) {
		const validityState = input.validity;
		input.setCustomValidity("");
		if (validityState.valueMissing) {
			input.setCustomValidity(t`Rhowch eich cyfeiriad e-bost yma`);
		} else if (validityState.typeMismatch) {
			input.setCustomValidity(t`Rhowch gyfeiriad e-bost dilys yma`);
		} else if (!validityState.valid) {
			input.setCustomValidity(t`Dyw hyn ddim yn ddilys.`);
		} else {
			input.setCustomValidity("");
		}
		input.reportValidity();
		return validityState.valid;
	}

	if (signInCheckStatus === 'loading') {
		children = <Prysur/>;
	}
	else if (!cyhoeddus && (!signInCheckResult.signedIn || !signInCheckResult.hasRequiredClaims)) {
		children = <Mewngofnodi gweithred={gweithred} onSubmit={handleEmailSubmit}/>
		// children = <RouterProvider router={router}><Mewngofnodi gweithred={gweithred} onSubmit={handleEmailSubmit}/></RouterProvider>;
	}

	return (
		<AppContext.Provider value={{app, setApp}}>
			<I18nProvider i18n={i18n}>
				{children}
			</I18nProvider>
		</AppContext.Provider>
	);

}

async function anfonDolen(ebost) {
	try {
		window.localStorage.setItem('ebost', ebost);
	} catch (err) {
		console.error(err);
	}
	const url = 'https://' + window.location.hostname + '/pencefn/api/ebost.php';
	const body = new URLSearchParams({
		url: window.location.protocol + '//' + window.location.host + window.location.pathname,
		math: "mewngofnod",
		ebost,
	});
	const response = await fetch(url, {
		method: 'POST',
		credentials: 'omit',
		body,
	});
	if (!response.ok) {
		console.error('gwall', response);
		return false;
	}
	const ateb = await response.text();
	return ateb === 'iawn';
}