import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Trans } from '@lingui/macro'
import { updateDoc } from 'firebase/firestore';

export default function DullTocynnau({dogfen}) {
	const [dull, setDull] = useState(
		dogfen.dull === 'dewis' && Date.now() > 1722758400000 ? 'casglu' : dogfen.dull
	);
	const [diolch, setDiolch] = useState(false);

	async function arGlic(e) {
		e.preventDefault();
		setDiolch(true);
		setDull('edocyn_grwp');
		await updateDoc(dogfen.ref, {dull: 'edocyn_grwp'});
	}

	if (!dull) return '';

	return (
		<Alert show={true} variant="primary">
			<Alert.Heading><Trans>Tocynnau mynediad</Trans></Alert.Heading>
			{ diolch &&
				<p><b><Trans>Diolch. Cofnodwyd eich dewis.</Trans></b></p>
			}
			{ (dull === 'casglu' || dull === 'dewis') &&
				<p><Trans>Bydd eich tocynnau mynediad <b>ar gael i’w casglu o’r swyddfa docynnau</b> ar ddiwrnod eich perfformiad.</Trans></p>
			}
			{ dull === 'dewis' &&
				<p><Trans>Os hoffech chi dderbyn e-docynnau ar ran pawb sy’n cymeryd rhan, wedi eu hanfon at gyfeiriad ebost y cyfrif hwn, dewiswch hynny isod erbyn 09:00 fore Sul 4 Awst.</Trans></p>
			}
			{ dull === 'edocyn' &&
				<p><Trans>Bydd pawb sy’n cymeryd rhan yn <b>derbyn e-docyn unigol dros ebost</b>.</Trans></p>
			}
			{ dull === 'edocyn_grwp' &&
				<p><Trans>Byddwch yn derbyn e-docynnau dros e-bost.</Trans></p>
			}
			{ dull === 'dewis' && <>
				<hr />
				<Button onClick={arGlic} variant="primary">
					Hoffwn dderbyn e-docynnau
				</Button>
				</>
			}
	</Alert>
	);
}