import Form from 'react-bootstrap/Form';
import { useFirestore } from 'reactfire';
import { Trans, t } from '@lingui/macro'
import { getDocs, query, where, or, documentId } from 'firebase/firestore';
import { caelCasgliad, Dyddiad, gwirioParthEbost } from '../cyffredinol';
import { useEffect, useState, useId } from 'react';

const LLEFYDD_CASGLU = [
	'maes-b', 'caffi-maes-b', 'llwyfan-maes', 'ty-gwerin', 'stryd-dawns'
];

export default function MaesTocynnau({disabled, slot, cais, cyst, artist}) {

	const id = useId();
	const firestore = useFirestore();
	const [dyddiau, setDyddiau] = useState(null);
	const [tocynnau, setTocynnau] = useState(
		cais?.tocynnau?.length ? cais.tocynnau : [{}]
	);
	const edocyn = !slot?.lle || !LLEFYDD_CASGLU.includes(slot.lle);
	const [opsiynauDanfon, setOpsiynauDanfon] = useState({
		unigol: edocyn && cais?.tocynnau?.length > 1 && !cais?.tocynnau?.filter(t => !t.ebost).length,
		cyfun: edocyn,
		casglu: true,
	});
	const [danfon, setDanfon] = useState(dewisDanfon(opsiynauDanfon));

	const labeliDanfon = {
		unigol: 'Anfon tocyn ebost at bob unigolyn',
		cyfun: 'Anfon tocynnau ebost ataf i',
		casglu: 'Casglu’r tocynnau o’r swyddfa docynnau ar y dydd'
	};
	const galluDanfon = false;
	// const [ebyst, setEbyst] = useState(cais?.tocynnau?.length ? cais.tocynnau : [{}]);

	// useEffect(() => {
	// 	if (artist?.ebost && (!tocynnau || !tocynnau.length)) {
	// 		setTocynnau([{enw: artist.enw, ebost: artist.ebost}]);
	// 	}
	// }, []);
	useEffect(() => {
		async function caelDyddiau() {
			const dyddiau = new Set();
			const querySnapshot = await getDocs(query(
				caelCasgliad(firestore, 'amserlen'),
				or(
					where(documentId(), '==', slot.id),
					where(documentId(), 'in', slot._plant),
				)
			));
			if (!querySnapshot.empty) {
				for (const doc of querySnapshot.docs) {
					dyddiau.add(doc.get('dydd'));
				}
				if (dyddiau.size) {
					setDyddiau([...dyddiau].sort());
				}
			}
		}
		if (slot?._plant) caelDyddiau();
	}, [slot]);

	function arYchwanegu(e) {
		e.preventDefault();
		setTocynnau([...tocynnau,{}]);
	}

	// Peidio caniatau dim dyddiau
	function arNewidDyddiau(e) {
		for (const check of e.currentTarget.elements) {
			if (check.checked) return;
		}
		for (const check of e.currentTarget.elements) {
			check.checked = check !== e.target;
		}
	}

	function arNewidEbost(e) {
		if (!edocyn) return;
		const inputs = e.target.form.querySelectorAll('.form-control-email');
		let unigol = true;
		if (inputs.length > 1) {
			for (const input of inputs) {
				if (!input.value || !input.validity.valid) {
					unigol = false;
					break;
				}
			}
		} else {
			unigol = false;
		}
		const opsiynau = {...opsiynauDanfon, unigol};
		setOpsiynauDanfon(opsiynau);
		if (!opsiynau[danfon]) {
			setDanfon(dewisDanfon(opsiynau));
		}
	}

	function dewisDanfon(opsiynau) {
		for (const dewis of [cais.danfon, 'unigol', 'cyfun']) {
			if (dewis && opsiynau[dewis]) return dewis;
		}
		return 'casglu';
	}

	return (
		<div className="rows mb-4">
			<div className="row" key="-1">
				<div className="col">
					<label className="form-label"><Trans>Enw llawn</Trans></label>
				</div>
				<div className="col">
					<label className="form-label"><Trans>Cyfeiriad ebost</Trans></label>
				</div>
				{ !cyst &&
					<div className="col">
						<label className="form-label"><Trans>Rôl yn y perfformiad</Trans></label>
					</div>
				}
			</div>
			{ tocynnau.map((eitem, i) => {
				const disabled = !!eitem.id || eitem.statws === 'canslo';
				return (
					<div key={i} className="row mb-3">
						{
							eitem.id && <input type="hidden" name={`tocynnau.${i}.id`} data-name="id" value={eitem.id}/>
						}
						{
							eitem.statws && <input type="hidden" name={`tocynnau.${i}.statws`} data-name="statws" value={eitem.statws}/>
						}
						<div className="col">
							<input readOnly={disabled} type="text" data-name="enw" defaultValue={eitem.enw} className="form-control" name={`tocynnau.${i}.enw`} autoComplete="off" placeholder={t`Enw llawn`}/>
						</div>
						<div className="col">
							<MaesGwirioEbostTocyn readOnly={disabled} defaultValue={eitem.ebost} onChange={arNewidEbost} name={`tocynnau.${i}.ebost`} placeholder={t`Cyfeiriad ebost`}/>
						</div>
						{ !cyst &&
							<div className="col">
								<input type="text" readOnly={disabled} defaultValue={eitem.rol} className="form-control" name={`tocynnau.${i}.rol`} autoComplete="off" placeholder={t`Rôl`}/>
							</div>
						}
						{ dyddiau &&
							<div className="col col-12">
								<fieldset className="border-bottom py-2" onChange={arNewidDyddiau}>
									<b className="me-3"><Trans>Dyddiau</Trans></b>&nbsp;
									{ dyddiau.map(dydd => {
										const checked = !eitem.dyddiau?.length || eitem.dyddiau.includes(dydd);
										return (
										<Form.Check inline key={dydd} id={id + i + dydd}
											name={`tocynnau.${i}.dyddiau[]`} value={dydd} defaultChecked={checked}
											label={<Dyddiad dyddiad={dydd} fformat="EE d"
											readOnly={disabled}/>}
										/>
										);
									} ) }
								</fieldset>
							</div>
						}
					</div>
				);
			})}
			{/* <p><Trans>Rydych chi wedi archebu {tocynnau?.length||0} o docynnau.</Trans></p> */}
			{ !disabled && <div className="mb-4">
				<button type="button" className="btn btn-secondary" onClick={arYchwanegu}>
					<span><Trans>Ychwanegu rhes</Trans></span>
				</button>
			</div> }

			{ galluDanfon && Object.values(opsiynauDanfon).filter(x => x).length > 1 &&
				<Form.Group className="mb-4">
					<Form.Label><Trans>Danfon tocynnau</Trans></Form.Label>
					<div className="form-text text-body-secondary mb-2">
						<Trans>Nodwch na fydd yn bosib i ni wireddu eich dewis bob tro. Byddwch yn derbyn ebost yn ystod yr wythnos cyn yr Eisteddfod yn cadarnhau’r manylion.</Trans>
					</div>
					{ Object.entries(opsiynauDanfon).map(([name, value]) => {
						if (!value) return '';
						return (
							<Form.Check key={name} type="radio" id={id + name}
								name="danfon" value={name}
								label={labeliDanfon[name]}
								checked={name === danfon}
								onChange={e => setDanfon(e.target.value)}
							/>
						);
					})}
				</Form.Group>
			}

		</div>
	);
}

function MaesGwirioEbostTocyn({readOnly, disabled, name, defaultValue, onChange, placeholder}) {

	const [nodyn, setNodyn] = useState('');

	async function arNewid(e) {
		await gwirioMaes(e.target);
		onChange && onChange(e);
	}

	async function gwirioMaes(target) {
		const validity = target.validity;
		const ebost = target.value.trim().toLowerCase();
		target.closest('.row').querySelector('[data-name="enw"]').required = !!ebost;
		if (validity.badInput || validity.typeMismatch || validity.patternMismatch || validity.valueMissing) {
			target.classList.add('is-invalid');
			target.setCustomValidity('Rhowch gyfeiriad ebost dilys.');
			setNodyn(t`Rhowch gyfeiriad ebost dilys neu gadewch yn wag.`);
			return;
		}
		if (ebost) {
			// const ebyst = [...target.form.elements.ebost].map(n => n.value.trim().toLowerCase());
			const inputs = target.form.querySelectorAll('.form-control-email');
			if (inputs.length > 1) {
				for (const input of inputs) {
					if (input !== target && input.value.trim().toLowerCase() === ebost) {
						target.classList.add('is-invalid');
						target.setCustomValidity('Rhowch gyfeiriad ebost gwahanol.');
						setNodyn(t`Rhowch gyfeiriad unigryw i bob person, neu gadewch yn wag.`);
						return;
					}
				}
			}
			setNodyn(true);
			const dilys = await gwirioParthEbost(ebost);
			if (dilys === false) {
				target.classList.add('is-invalid');
				target.setCustomValidity('Rhowch gyfeiriad ebost dilys.');
				setNodyn(t`Mae’r cyfeiriad yn anghywir neu’n annilys.`);
				return;
			}
		}
		target.classList.remove('is-invalid');
		target.setCustomValidity('');
		setNodyn(false);
	}

	return (
		<div>
			<input disabled={disabled} readOnly={readOnly} onChange={arNewid} type="email" name={name || 'ebost'}
			pattern="[a-zA-Z0-9.!#$%&\'*+\\/=?^_`\\{\\|\\}~\\-]+@[a-zA-Z0-9\\-]{2,}(?:\\.[a-zA-Z0-9\\-]{2,})+"
			defaultValue={defaultValue} className="form-control form-control-email"
			inputMode="email" autoComplete="off" placeholder={placeholder}/>
			{nodyn && nodyn !== true && <div className="form-text text-danger">{nodyn}</div>}
		</div>
	);

}
