import { query, where, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { caelCasgliad } from '../cyffredinol';
import RhestrSlotiau from './RhestrSlotiau';

export default function AmserlenCystadlu({cystadleuaeth}) {
	const casgliad = caelCasgliad(useFirestore(), 'amserlen');
	const ymholiad = query(
		casgliad,
		where('rhifau', 'array-contains', cystadleuaeth.rhif),
		where('dangos', 'in', ['barod', 'golygwyd', 'cyhoeddus']),
		orderBy('dechrau'),
		orderBy('enw'),
	);
	const {status, data} = useFirestoreCollectionData(ymholiad, {idField: 'id'});
	if (status === 'loading') {
		return;
	}
	return <RhestrSlotiau slotiau={data} cystadlu={true}/>;

}
