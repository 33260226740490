import { Trans, t } from '@lingui/macro'
import Bloc from './Bloc';
import { Botwm } from '../routes/cais.jsx';
import { updateDoc } from 'firebase/firestore';
import { glanhauTestun, formDataObject } from '../cyffredinol';
import { forwardRef, useRef, useImperativeHandle, useCallback } from 'react';
import MaesTestun from '../darnau/MaesTestun';
import MaesTocynnau from '../darnau/MaesTocynnau';

const Tocynnau = forwardRef(function Tocynnau({cais, slot, cyst, artist, blociau, setBlociau, nesaf}, ref) {

	const bloc = 'tocynnau';
	const statws = blociau[bloc] ? 'cwblhawyd' : '';
	const formRef = useRef(null);
	const disabled = false;//!!cais.tocynnau?.[0]?.id;

	function arAnfon(e) {
		e.preventDefault();
		if (disabled) {
			nesaf();
		} else if (!e.target.checkValidity()) {
			e.target.reportValidity();
		} else if (cadwFfurflen(e.target)) {
			nesaf();
		}
	}

	const cadwFfurflen = useCallback((form) => {
		const formData = new FormData(form);
		const data = formDataObject(formData, ['tocynnau'], true);
		if ('tocynnau' in data) {
			data.tocynnau = data.tocynnau?.filter(tocyn => tocyn.enw).map(tocyn => {
				for (const key of Object.keys(tocyn)) {
					if (['enw', 'ebost', 'rol', 'id', 'statws'].includes(key)) {
						tocyn[key] = glanhauTestun(tocyn[key]);
					} else if (key === 'dyddiau') {
						tocyn.dyddiau = tocyn.dyddiau.map(glanhauTestun);
					} else {
						delete tocyn[key];
					}
				}
				return tocyn;
			});
		}
		for (const key of ['hygyrchedd', 'danfon']) {
			if (formData.has(key)) {
				const val = glanhauTestun(formData.get(key));
				if (val !== cais[val]) {
					data[key] = val;
				}
			}
		}
		const cwblhawyd = (form.checkValidity() && data.tocynnau?.length) || false;
		if ( (cwblhawyd !== !!blociau[bloc]) && ('tocynnau' in data) ) {
			data[`gofynion.${bloc}`] = cwblhawyd;
			blociau[bloc] = cwblhawyd;
			setBlociau({...blociau});
		}
		if (Object.keys(data).length) {
			updateDoc(cais.ref, data);
		}
		return true;
	}, [cais, blociau, setBlociau]);

	useImperativeHandle(ref, () => {
		return {
			cadw() {
				return cadwFfurflen(formRef.current);
			},
		};
	}, [cadwFfurflen]);

	return (
	<Bloc disabled={disabled} label={t`Tocynnau mynediad`} formRef={formRef} eventKey={bloc} statws={statws} onSubmit={arAnfon}>
		{ cyst ?
			<>
			<p><Trans>Rhowch enw llawn pob unigolyn sy’n cymryd rhan, un enw i bob rhes.</Trans></p>
			<p><Trans>Nodwch y manylion cyswllt os yn hysbys, ar gyfer unrhyw un 16 oed neu’n hŷn.</Trans></p>
			<p><Trans>Rhaid i’r nifer o docynnau a archebir gyfateb gyda’r nifer sy’n ymddangos ar y llwyfan ar y diwrnod.</Trans></p>
			<p><Trans>Byddwch yn derbyn ebost yn ystod yr wythnos cyn yr Eisteddfod gyda manylion sut i gasglu eich tocynnau.</Trans></p>
			</>
		:
			<>
			<p><Trans>Rhowch enw llawn pob unigolyn sy’n cymryd rhan, un enw i bob rhes.</Trans></p>
			<p><Trans>Nodwch y manylion cyswllt os yn hysbys (ar gyfer unrhyw un 16 oed neu’n hŷn), a’u rôl yn y perfformiad os yn berthnasol.</Trans></p>
			{slot?._plant && <p><Trans>O dan pob enw, nodwch ar ba ddyddiau bydd y person yn bresennol.</Trans></p> }
			<p><Trans>Byddwch yn derbyn ebost yn ystod yr wythnos cyn yr Eisteddfod gyda manylion sut i gasglu eich tocynnau.</Trans></p>
			</>
		}

		<MaesTocynnau disabled={disabled} slot={slot} cais={cais} cyst={cyst} artist={artist}/>

		<MaesTestun name="hygyrchedd" defaultValue={cais.hygyrchedd} type="textarea" label={ t`Anghenion hygyrchedd` } rows="5">
			<Trans>Nodwch unrhyw anghenion mynediad, ynghyd â manylion cyswllt argyfwng os oes angen.</Trans>
		</MaesTestun>

		<Botwm/>
	</Bloc>
	);
});

export default Tocynnau;