import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Trans, t } from '@lingui/macro';
import Neges from './Neges';
import NewidIaith from './NewidIaith';
import MaesTestun from './MaesTestun';
import logo from '../media/eisteddfod.svg';
import { QuestionCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";

function Mewngofnodi({ gweithred, onSubmit }) {
	return (
	<div className="container container-max-sm prif">
		<main className="main my-5">
			<div className="mb-3 text-center">
				<img src={ logo } alt="Eisteddfod" width="250" height="42"/>
			</div>
			<div className="mb-5 text-center">
				<NewidIaith/>
			</div>
			<Neges/>
			<Form onSubmit={onSubmit} noValidate>
				<MaesTestun size="lg" label={ t`Cyfeiriad e-bost` } name="ebost" type="email" autoComplete="email" defaultValue={window.localStorage?.getItem('ebost')} required={true}/>
				<Button size="lg" variant="primary" type="submit" className="w-100">{ gweithred === 'anfon' ? t`Anfon` : t`Cadarnhau` }</Button>
			</Form>
			<div className="mt-3 small text-body-secondary">
				<p><a href="/cofrestru/cymorth" className="btn btn-sm btn-link ps-0">
					<QuestionCircle/>
					<span><Trans>Cymorth</Trans></span>
				</a></p>
				<p><Trans>Os oes gennych chi unrhyw gwestiynau am y cystadlaethau, cysylltwch â <a href="mailto:cystadlu@eisteddfod.cymru">cystadlu@eisteddfod.cymru</a>.</Trans></p>
				<p><Trans>Os ydych chi’n profi anawsterau technegol wrth ddefnyddio’r wefan hon, cysylltwch â <a href="mailto:cymorth@artistiaid-eisteddfod.cymru">cymorth@artistiaid-eisteddfod.cymru</a>.</Trans></p>
			</div>
		</main>
	</div>
	);
}

export default Mewngofnodi;