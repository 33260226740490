import { useFirestore, useFirestoreDocData } from 'reactfire';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';
import { gwraiddCasgliad, glanhauTestun } from '../cyffredinol';
import MaesTestun from '../darnau/MaesTestun';
import Prysur from '../darnau/Prysur';
import { Trans, t } from '@lingui/macro'
import Button from 'react-bootstrap/Button';

export default function ManylionBanc({dogfen, cystadleuaeth}) {

	const form = useRef();
	const [stad, setStad] = useState(false);

	const firestore = useFirestore();
	const docRef = doc(firestore, gwraiddCasgliad(`ceisiadau/${dogfen.id}/preifat`), 'banc');
	const { status, data: banc } = useFirestoreDocData(docRef, {idField: 'id'});
	const llenwyd = status !== 'loading' && banc?.enw && banc?.rhif && banc?.cod;
	const ysgoloriaeth = cystadleuaeth.ysgoloriaeth && dogfen.safle === 1;

	// useEffect(() => {
	// 	if (status !== 'loading' && banc?.enw && banc?.rhif && banc?.cod) {
	// 		setStad('llenwyd');
	// 	}
	// }, [status, banc]);

	function arAnfon(e) {
		e.preventDefault();
		if (!e.target.checkValidity()) {
			e.target.reportValidity();
			return;
		}
		const formData = new FormData(e.target);
		const data = {};
		for (const key of ['enw', 'rhif', 'cod', 'ysgoloriaeth']) {
			if (formData.has(key)) {
				const val = glanhauTestun(formData.get(key));
				if (!banc || val !== banc[val]) {
					data[key] = val;
				}
			}
		}
		if (Object.keys(data).length) {
			setDoc(docRef, data, {merge: true});
		}
		if (!dogfen?.gofynion?.banc) {
			updateDoc(dogfen.ref, {
				"gofynion.banc": true
			});
		}
		setStad('cadwyd');
		return true;
	}

	if (status === 'loading') {
		return <Prysur/>
	}

	return (
		<>{
		stad === 'cadwyd' ?
			<p><Trans>Diolch. Cadwyd eich manylion banc.</Trans></p>
		: (llenwyd ? <p><Trans>Rydych chi wedi cofnodi eich manylion banc.</Trans></p>
			: <form ref={form} onSubmit={arAnfon} className="mb-3">
				<p><Trans>Bydd unrhyw wobrau ariannol yn cael eu talu i’r cyfrif hwn.</Trans></p>
				<MaesTestun name="enw" required gwall={t`Rhowch enw eich cyfrif banc.`} defaultValue={ banc?.enw } label={ t`Enw’r cyfrif` } autoComplete="name"/>
				<MaesTestun name="rhif" required gwall={t`Rhowch rif cyfrif 8 digid o hyd.`} defaultValue={ banc?.rhif } label={ t`Rhif y cyfrif` } placeholder="00000000" inputMode="numeric" pattern="\d{8}" max="99999999"/>
				<MaesTestun name="cod" required gwall={t`Rhowch god didoli 6 digid o hyd. Nid oes angen cynnwys dash.`}defaultValue={ banc?.cod } label={ t`Cod didoli’r cyfrif` } placeholder="000000" inputMode="numeric" pattern="\d\d-?\d\d-?\d\d"/>
				{ ysgoloriaeth && <MaesTestun gair="100" name="ysgoloriaeth" defaultValue={ banc?.ysgoloriaeth } type="textarea" label={ t`Ysgoloriaeth` } rows="5">
					<Trans>Nodwch yn gryno sut y fyddwch chi’n defnyddio unrhyw arian ysgoloriaeth sy’n rhan o’r wobr</Trans>
				</MaesTestun> }
				<Button variant="primary" type="submit" className="ms-auto"><span><Trans>Cadw</Trans></span></Button>
			</form>
		)
		}
		<p><Trans>Nodwch y bydd angen llenwi’r manylion hyn ar wahân i bob un o’ch ceisiadau sy’n berthnasol.</Trans></p>
		</>
	);

}