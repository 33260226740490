import { useRouteError } from "react-router-dom";
import { Trans } from '@lingui/macro';

export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<div id="error-page" className="m-4">
			<h1><Trans>Wps</Trans></h1>
			<p><Trans>Ymddiheuriadau, mae problem wedi codi.</Trans></p>
            <p><Trans>Os ydych chi’n profi anawsterau technegol wrth ddefnyddio’r wefan hon, cysylltwch â <a href="mailto:cymorth@artistiaid-eisteddfod.cymru">cymorth@artistiaid-eisteddfod.cymru</a>.</Trans></p>
		    <p className="text-body-secondary small"><i>{error.statusText || error.message}</i></p>
		</div>
	);
}